<template>
  <div v-cloak>
    <transition name="fade" appear appear-active-class="fade-enter-active">
      <div v-if="apiLoaded || errorText">
        <div v-if="apiLoaded" class="wrap">
          <div class="list passport" v-if="config != null">
            <div class="list-item-wrap">
              <v-expansion-panels>
                <ResultMonitorChecklistSection
                    v-for="sectionName in configSectionNames"
                    :key="sectionName"
                    :regionAndDepartmentInfo="regionAndDepartmentInfo"
                    :title="sectionName"
                    :questions="configSections[sectionName]"
                    :values="getFilteredValuesBySectionName(sectionName)"
                    :period="period"
                    @updated="editDialogUpdated"
                ></ResultMonitorChecklistSection>
              </v-expansion-panels>
            </div>
          </div>
        </div>
      </div>
      <div v-else style="height:1000px"></div>
    </transition>
  </div>
</template>


<script>

import {
  filterValues,
  loadChecklistConfig,
  loadRegionValuesByRegionCodeAndPeriod, loadValuesByDeparmentIdAndPeriod
} from "@/modules/ResultMonitor";
import ResultMonitorChecklistSection from "@/components/resultmonitor/ResultMonitorChecklistSection";

export default {
  name: "ResultMonitorCheckList",
  components: {ResultMonitorChecklistSection},
  props: ['regionAndDepartmentInfo', 'period'],
  watch: {
    regionAndDepartmentInfo: {
      async handler() {
        //console.log(this.regionAndDepartmentInfo)
        this.apiLoaded = false;
        await this.initialize();
        this.$forceUpdate();
        this.apiLoaded = true;
      },
      deep: true
    },
    period: {
      async handler() {
        this.apiLoaded = false;
        await this.initialize();
        this.$forceUpdate();
        this.apiLoaded = true;
      }
    }
  },

  data: () => ({
    configSectionNames: [],
    configSections: [],
    id: null,
    config: null,
    checklistValues: [],
    apiLoaded: false,
    errorText: null,
  }),

  methods: {
    async initialize() {
      await Promise.all(
          [
            this.loadConfig(),
            this.loadValues()]
      )
    },

    async loadConfig() {
      this.config = await loadChecklistConfig()
      this.computeConfigSectionsInfo()
    },

    // разбивает вопросы чеклиста по секциям
    computeConfigSectionsInfo(){
      const sectionField = this.regionAndDepartmentInfo.department == null ? 'regionSection' : 'cznSection'
      this.configSectionNames = [...new Set(this.config.map(e=>e[sectionField]))].filter(e=>e!=null)
      this.configSections = {}
      for(let sectionName of this.configSectionNames){
        this.configSections[sectionName] = this.config.filter(e=>e[sectionField] === sectionName)
      }
    },

    async loadValues() {
      if (this.regionAndDepartmentInfo.department == null) {
        this.checklistValues =
            await loadRegionValuesByRegionCodeAndPeriod(this.regionAndDepartmentInfo.region.code, this.period)
      } else {
        this.checklistValues =
            await loadValuesByDeparmentIdAndPeriod(this.regionAndDepartmentInfo.department.id, this.period)
      }
    },

    getFilteredValuesBySectionName(sectionName){
      return filterValues(this.checklistValues, this.configSections[sectionName])
    },


    checkAnswerState(flag) {
      if (flag == null) {
        return 'none';
      } else if (flag === 3) {
        return 'red';
      } else if (flag === 2) {
        return 'yellow'
      } else if (flag === 1) {
        return 'green'
      }
    },

    isEmptyValue(value) {
      if (!value) {
        return true
      }
      return (value.attachments == null || value.attachments.length === 0) &&
          (value.currentValue == null);
    },

    async editDialogUpdated() {
      await this.loadValues();
      this.$forceUpdate()
    },
  },

  async beforeMount() {
    await this.initialize()
    this.apiLoaded = true;
  },
}
</script>

<style scoped lang="scss">
@import "../../styles/main.scss";

h1 {
  display: flex;
  align-items: center;

  .badge {
    width: 152px;
    height: 60px;
    background: #E8E8E9;
    border: 1px solid #E8E8E9;
    border-radius: 4px;
    font-size: 14px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-left: 70px;
    color: $cblack;
  }

  .v-progress-circular {
    margin-left: 20px;
  }

  .v-btn {
    margin-left: auto;
  }
}

.manual-link {
  margin: 20px 0;

  a {
    display: flex;
    align-items: center;
    text-decoration: none;
    margin-bottom: 20px;

    .v-icon {
      color: #0033a0;
      margin-right: 10px;
    }
  }
}

.list {
  .list-item-wrap {
    padding: 5px 0;
  }

  table {
    margin-bottom: 25px;

    th {
      padding: 5px 20px 5px 0;

      div.v-dialog__container + div {
        height: 24px;
        cursor: pointer;
      }

      span {
        display: flex;
        justify-content: flex-start;
        gap: 3px;
        min-width: min(150px, 20vw);
      }

      &:first-child {
        text-align: center;
        width: 100%;
      }
    }

    td {
      padding: 5px 20px 5px 0;
      border-top: 1px solid #E8E8E9;
      border-bottom: 0;

      & > div {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;

        svg {
          margin-right: 8px;
          flex-shrink: 0;
          margin-top: 5px;
          width: 20px;
        }

        a {
          white-space: nowrap;
        }
      }
    }

    tr:last-child {
      td {
        border-top: 1px solid #E8E8E9;
      }
    }
  }
}

.clientindex {
  .v-expansion-panel.level2 {
    padding-left: 30px;
    padding-top: 0px;

    .list-item-wrap {
      border-bottom: 0;
    }

    & + .v-expansion-panel.level2 {
      .list-item-wrap {
        border-top: 1px solid #E8E8E9;
      }
    }
  }
}

.push-right {
  float: right;
  margin-top: 15px;
}

.v-progress-circular__info {
  font-size: 13px !important;
  text-align: center;
  width: 100%;
}
</style>
