<template>
  <div v-cloak>
    <transition name="fade" appear appear-active-class="fade-enter-active">
      <div v-if="apiLoaded || errorText">
        <div v-if="apiLoaded" class="wrap">
          <template v-if="['ROLE_ADMIN', 'ROLE_VNII', 'ROLE_MINTRUD', 'ROLE_REGIONAL_AGENCY'].includes($user.role)">
            <h3>Рейтинги субъектов Российской Федерации (служб занятости населения, СЗН) в целях анализа
              результативности оказания государственных услуг</h3>
            <div class="list">
              <div class="report-list">
                <ResultMonitorRating
                    v-for="(rating, ratingIndex) in federal_ratings"
                    :key="'federal_rating_'+ratingIndex"
                    :title="(ratingIndex+1) + ' ' + rating.title"
                    :period="period"
                    :report-data-function="rating.dataFunction"
                    :region-and-department-info="regionAndDepartmentInfo"
                    :only-current-region="['ROLE_REGIONAL_AGENCY', 'ROLE_CZN_MANAGER'].includes($user.role)"
                ></ResultMonitorRating>
              </div>
            </div>

            <h3>Рейтинги субъектов Российской Федерации (служб занятости населения, СЗН) в целях оценки эффективности и
              результативности деятельности органов службы занятости населения</h3>
            <div class="list">
              <div class="report-list">
                <ResultMonitorRating
                    v-for="(rating, ratingIndex) in effectiveness_ratings"
                    :key="'effect_rating_'+ratingIndex"
                    :title="(ratingIndex+1) + ' ' + rating.title"
                    :period="period"
                    :report-data-function="rating.dataFunction"
                    :region-and-department-info="regionAndDepartmentInfo"
                    :only-current-region="['ROLE_REGIONAL_AGENCY', 'ROLE_CZN_MANAGER'].includes($user.role)"
                ></ResultMonitorRating>
              </div>
            </div>
          </template>


          <template v-if="['ROLE_ADMIN', 'ROLE_VNII', 'ROLE_MINTRUD', 'ROLE_REGIONAL_AGENCY', 'ROLE_REGIONAL_ADMINISTRATOR', 'ROLE_CZN_MANAGER'].includes($user.role)">
            <h3>Рейтинги ЦЗН субъекта РФ ({{ regionAndDepartmentInfo?.region?.name }})</h3>
            <div class="list">
              <div class="report-list">
                <ResultMonitorRating
                    v-for="(rating, ratingIndex) in czn_ratings"
                    :key="'federal_rating_'+ratingIndex"
                    :title="(ratingIndex+1) + ' ' + rating.title"
                    :period="period"
                    :report-data-function="rating.dataFunction"
                    :region-and-department-info="regionAndDepartmentInfo"
                ></ResultMonitorRating>
              </div>
            </div>
          </template>
        </div>
        <div v-else style="height:1000px"></div>
      </div>
    </transition>
    <LoaderDialog :show="showLoaderDialog"></LoaderDialog>

  </div>
</template>


<script>

import LoaderDialog from "@/components/elements/LoaderDialog";
import {
  effectivenessRatings,
  getRatingByIndicator,
  getRatingInvalidsService, getRatingProfOrientation, getRatingTemporaryService,
  getRatingWorksearchService,
  getServiceIntegralRating, resultMonitorRatings,
  setDepartmentsByRegion
} from "@/modules/ResultMonitorReports";
import ResultMonitorRating from "@/components/resultmonitor/ResultMonitorRating";

export default {
  name: "ResultMonitorRatings",
  components: {ResultMonitorRating, LoaderDialog},
  props: ['regionAndDepartmentInfo', 'period'],

  data: () => ({
    id: null,
    configId: null,
    config: {},
    checklistValues: [],
    formData: {},
    parametersTextPartMap: new Map(),
    parametersFilePartMap: new Map(),
    parametersAnswerStatusesPartMap: new Map(),
    parametersControllerNotesPartMap: new Map(),
    apiLoaded: false,
    errorText: null,
    dialog: false,
    editable: false,
    currentValues: true,
    quarter: 1,
    componentKey: 0,
    checkListExportDialog: false,
    exportDialogKey: 0,
    showLoaderDialog: false,
    chartData: [],
    federal_ratings: resultMonitorRatings,

    effectiveness_ratings: effectivenessRatings,


    czn_ratings: [
      {
        id: 50,
        title: 'Интегральный рейтинг результативности оказания государственных услуг',
        dataFunction: getServiceIntegralRating.bind(null, 'CZN')
      },
      {
        id: 31,
        title: 'Сводный рейтинг результативности оказания государственной услуги «Содействие гражданам в поиске подходящей работы»',
        dataFunction: getRatingWorksearchService.bind(null, 'CZN')
      },
      {
        id: 32,
        title: 'Сводный рейтинг результативности оказания государственной услуги «Содействие работодателям в подборе необходимых работников»',
        dataFunction: getRatingByIndicator.bind(null, 'CZN', 5)
      },
      {
        id: 33,
        title: 'Сводный рейтинг результативности оказания государственной услуги «Организация профессионального обучения и дополнительного профессионального образования безработных граждан, включая обучение в другой местности»',
        dataFunction: getRatingByIndicator.bind(null, 'CZN', 7)
      },
      {
        id: 34,
        title: 'Сводный рейтинг результативности оказания государственной услуги «Организация профессиональной ориентации граждан»',
        dataFunction: getRatingProfOrientation.bind(null, 'CZN')
      },
      {
        id: 35,
        title: 'Сводный рейтинг результативности оказания государственной услуги «Содействие началу осуществления предпринимательской деятельности»',
        dataFunction: getRatingByIndicator.bind(null, 'CZN', 12)
      },
      {
        id: 36,
        title: 'Сводный рейтинг результативности оказания государственной услуги «Организация сопровождения при содействии занятости инвалидов», а также осуществления полномочий по содействию занятости инвалидов',
        dataFunction: getRatingInvalidsService.bind(null, 'CZN')
      },
      {
        id: 37,
        title: 'Сводный рейтинг результативности оказания государственной услуги «Психологическая под-держка безработных граждан»',
        dataFunction: getRatingByIndicator.bind(null, 'CZN', 17)
      },
      {
        id: 38,
        title: 'Сводный рейтинг результативности оказания государственной услуги «Социальная адаптация безработных граждан на рынке труда»',
        dataFunction: getRatingByIndicator.bind(null, 'CZN', 19)
      },
      {
        id: 39,
        title: 'Сводный рейтинг результативности оказания государственной услуги «Организация временного трудоустройства»',
        dataFunction: getRatingTemporaryService.bind(null, 'CZN')
      },
      {
        id: 40,
        title: 'Сводный рейтинг результативности оказания государственной услуги «Содействие безработным гражданам и гражданам, зарегистрированным в органах службы занятости в целях поиска подходящей работы, в переезде и безработным гражданам и гражданам, зарегистрированным в органах службы занятости в целях поиска подходящей работы, и членам их семей в переселении в другую местность для трудоустройства по направлению органов службы занятости»',
        dataFunction: getRatingByIndicator.bind(null, 'CZN', 27)
      },
      {
        id: 4243,
        title: 'Рейтинг субъектов РФ по показателю П2. Доля трудоустроенных на постоянную работу в численности граждан, обратившихся в целях поиска подходящей работы',
        dataFunction: getRatingByIndicator.bind(null, 'CZN', 2)
      },
      {
        id: 5,
        title: 'Рейтинг субъектов РФ по показателю П10. Доля безработных граждан, получивших государственную услугу "организация профессиональной ориентации граждан" перед направлением на профессиональное обучение, дополнительное профессиональное образование или нашедших доходное занятие после получения услуги "организация профессиональной ориентации граждан", в численности безработных граждан, получивших указанную услугу',
        dataFunction: getRatingByIndicator.bind(null, 'CZN', 10)
      },
      {
        id: 6,
        title: 'Рейтинг субъектов РФ по показателю П12. Доля безработных граждан, начавших предпринимательскую деятельность после получения государственной услуги "содействие началу осуществления предпринимательской деятельности безработных граждан", в численности безработных граждан, получивших указанную услугу',
        dataFunction: getRatingByIndicator.bind(null, 'CZN', 12)
      },
      {
        id: 7,
        title: 'Рейтинг субъектов РФ по показателю П14. Доля трудоустроенных инвалидов в общей численности инвалидов, получивших услугу "организация сопровождения при содействии занятости инвалидов"',
        dataFunction: getRatingByIndicator.bind(null, 'CZN', 14)
      },
      {
        id: 8,
        title: 'Рейтинг субъектов РФ по показателю П15. Доля трудоустроенных инвалидов из числа инвалидов, обратившихся в органы службы занятости за содействием в поиске подходящей работы',
        dataFunction: getRatingByIndicator.bind(null, 'CZN', 15)
      },
      {
        id: 9,
        title: 'Рейтинг субъектов РФ по показателю П17. Доля граждан, нашедших доходное занятие после получения государственной услуги "психологическая поддержка безработных граждан" в численности безработных граждан, получивших указанную услугу',
        dataFunction: getRatingByIndicator.bind(null, 'CZN', 17)
      },
      {
        id: 10,
        title: 'Рейтинг субъектов РФ по показателю П19. Доля граждан, нашедших доходное занятие после получения услуги "социальная адаптация безработных граждан на рынке труда" в численности безработных граждан, получивших указанную услугу',
        dataFunction: getRatingByIndicator.bind(null, 'CZN', 19)
      },
      {
        id: 11,
        title: 'Рейтинг субъектов РФ по показателю П23. Доля несовершеннолетних граждан в возрасте от 14 до 18 лет, трудоустроенных на временные работы в свободное от учебы время, из числа граждан данной категории, обратившихся за предоставлением государственной услуги «Организация временного трудоустройства» или согласились с предложением о ее предоставлении',
        dataFunction: getRatingByIndicator.bind(null, 'CZN', 23)
      },
      {
        id: 12,
        title: 'Рейтинг субъектов РФ по показателю П24. Доля безработных граждан, испытывающих трудности в поиске работы, трудоустроенных на временные работы, из числа безработных граждан, обратившихся за предоставлением государственной услуги "Организация временного трудоустройства" или согласились с предложением о ее предоставлении',
        dataFunction: getRatingByIndicator.bind(null, 'CZN', 24)
      },
      {
        id: 13,
        title: 'Доля безработных граждан в возрасте от 18 до 25 лет, имеющих высшее или среднее профессиональное образование, ищущих работу в течение года с даты выдачи им документа об образовании и о квалификации, трудоустроенных на временные работы, из числа граждан данной категории, обратившихся за предоставлением государственной услуги "Организация временного трудоустройства" или согласились с предложением о ее предоставлении',
        dataFunction: getRatingByIndicator.bind(null, 'CZN', 25)
      },
      {
        id: 27,
        title: 'Доля безработных граждан, получивших государственную услугу "Содействие безработным гражданам   и гражданам, зарегистрированным в органах службы занятости в целях поиска подходящей работы, в переезде и безработным гражданам и гражданам, зарегистрированным в органах службы занятости в целях поиска подходящей работы, и членам их семей в переселении в другую местность для трудоустройства по направлению органов службы занятости ", в численности граждан, обратившихся за указанной услугой или согласившихся с предложением о ее предоставлении',
        dataFunction: getRatingByIndicator.bind(null, 'CZN', 25)
      }
    ],


    downloadItems: [
      {
        id: 1,
        title: 'Скачать XLSX',
      }
    ],
  }),

  methods: {
    async downloadRating() {
      this.showLoaderDialog = true;

      let query = {
        headerCells: [
          {
            caption: "Субъект РФ",
            width: 10000,
          },
          {
            caption: "Позиция в рейтинге",
            width: 10000,
          },
          {
            caption: "Значение",
            width: 10000,
          }
        ],
        rows: []
      }
      let regionsCount = this.$cznDepartmentsByRegionList.length
      query.rows = this.$cznDepartmentsByRegionList.map(e => [
        {cellDataType: "STRING", value: e.name},
        {cellDataType: "STRING", value: 1 + " - " + regionsCount},
        {cellDataType: "INTEGER", value: 0},
      ])
      await this.$downloadApi("Рейтинг.xlsx", "/export/xlsx", query, true);
      this.showLoaderDialog = false;

    }
  },

  filters: {
    dateFormat: function (date) {
      if (date == null) {
        return ''
      }
      let mdate = new Date(date)
      let options = {timeZone: 'Europe/Moscow', year: 'numeric', month: 'numeric', day: 'numeric'}
      return mdate.toLocaleDateString('ru-RU', options)
    }
  },

  async beforeMount() {
    setDepartmentsByRegion(this.$cznDepartmentsByRegionList)
    let regionValues = this.$cznDepartmentsByRegionList.map(e => [e.name, 100])
    regionValues.unshift(["Субъект РФ", "Значение"])
    this.chartData = regionValues
    this.apiLoaded = true;
  },
}
</script>

<style scoped lang="scss">
h1 {
  display: flex;
  align-items: center;

  .badge {
    width: 152px;
    height: 60px;
    background: #E8E8E9;
    border: 1px solid #E8E8E9;
    border-radius: 4px;
    font-size: 14px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-left: 70px;
    color: $cblack;
  }

  .v-progress-circular {
    margin-left: 20px;
  }

  .v-btn {
    margin-left: auto;
  }
}

.manual-link {
  margin: 20px 0;

  a {
    display: flex;
    align-items: center;
    text-decoration: none;
    margin-bottom: 20px;

    .v-icon {
      color: #0033a0;
      margin-right: 10px;
    }
  }
}

.list {
  table {
    margin-bottom: 25px;

    th {
      border-bottom: 1px solid #E8E8E9;
    }

    td:nth-child(2),
    th:nth-child(2) {
      text-align: right;
    }

    td > div {
      display: flex;
      align-items: flex-start;
      justify-content: flex-end;

      svg {
        margin-right: 8px;
        flex-shrink: 0;
        margin-top: 5px;
      }
    }

    tr:last-child td {
      border-bottom: 1px solid #E8E8E9;
    }
  }
}

.push-right {
  float: right;
  margin-top: 15px;
}

.report-list {
  border-top: 1px solid #e8e8e9;

  .report-list__item {
    display: flex;
    flex-flow: row wrap;
    gap: 20px;
    justify-content: space-between;
    border-bottom: 1px solid #e8e8e9;
    padding: 5px 0;

    .report-list__title {
      flex: 0 0 calc(100% - 40px);

      .toggleSwitch {
        margin-right: 5px;
      }

      span {
        color: $cblue;
      }

      display: flex;
      flex-flow: row nowrap;
      align-items: center;

    }

    .report-list__download {
      background: url(~@/assets/download.svg) no-repeat center center;
      background-size: contain;
      flex: 0 0 20px;
      width: 20px;
      height: 20px;
      cursor: pointer;
    }

    .report-list__graph {
      width: 400px;
      max-width: 100%;
      height: 200px;
      background: $cgray;
      margin-bottom: 10px;
    }
  }
}

.v-menu__content.menuable__content__active {
  box-shadow: none;
  border: 1px solid #A0A0A0;
  border-radius: 0;

  .downloads {
    border-radius: 0;

    .v-list {
      padding: 0;
      background: #E9E9E9;

      .v-list-item {
        padding: 0;
        min-height: initial;

        .v-list-item__title {
          padding: 4px 8px;
          color: $cblue;
        }
      }
    }
  }
}

</style>
