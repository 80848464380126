//import api from "@/modules/api";
//const VERBOSE = true

import {resultMonitorChecklistConfig, resultMonitorIndicatorsConfig} from "@/modules/ResultMonitorConfig";
import {get, post} from "@/modules/remote";

/**
 * Тип "Показатель чеклиста мониторинга результативности (конфигурация)"
 *
 * @typedef {Object} ResultMonitorChecklistIndicator
 * @property {string} id
 * @property {string} name
 * @property {string} unit
 * @property {string} source
 * @property {string} checks
 * @property {'П'|'О'} type
 */

/**
 * Тип "Информация о контроле показателя результативности"
 *
 * @typedef {Object} ResultControlInfo
 * @property {?number} id
 * @property {null|1|2|3} flag
 * @property {?string} comment
 */

/**
 * Тип "Регион"
 *
 * @typedef {Object} Region
 * @property {string} code
 * @property {string} name
 * @property {number} year
 */

/**
 * Тип "Центр занятости населения"
 *
 * @typedef {Object} Department
 * @property {number} id
 * @property {('territorial_czn'|'manager_czn')} type
 * @property {string} name
 * @property {Region} region
 * @property {string} fullName
 * @property {string} address
 * @property {boolean} isActive
 * @property {string} coords
 *
 */


/**
 * Тип "Регион"
 *
 * @typedef {Object} RegionAndDepartmentInfo
 * @property {Region} region
 * @property {?Department} department
 */


/**
 * Тип "Значение показателя чеклиста мониторинга результативности"
 *
 * @typedef {Object} ResultMonitorChecklistValue
 * @property {?number} id
 * @property {string} configVersion
 * @property {string} regionCode
 * @property {?Department} department
 * @property {string} indicatorId
 * @property {?string} creationDateTime
 * @property {number} period
 * @property {?number} value
 * @property {?ResultControlInfo} controlInfo
 */

/**
 * @returns {Promise<Array.<ResultMonitorChecklistIndicator>>}
 */
export async function loadChecklistConfig() {
    return resultMonitorChecklistConfig
}

export async function loadIndicatorsConfig() {
    return resultMonitorIndicatorsConfig
}

export const resultMonitorChecklistSources = [{id: 0, name: 'РОИВ', title: 'Показатели, предоставляемые РОИВ', imported: false},
    {id: 1, name: 'Форма № 2-Т', title: 'Форма № 2-Т', imported: true},
    {id: 2, name: 'Форма № 1-Т', title: 'Форма № 1-Т', imported: true},
    {id: 3, name: 'Росстат, отчет «Количество организаций по данным государственной регистрации с 2017 г. по субъектам Российской Федерации и видам экономической деятельности»', title: 'Росстат, отчет «Количество организаций по данным государственной регистрации с 2017 г. по субъектам Российской Федерации и видам экономической деятельности»', imported: true},
    {id: 4, name: 'Росстат, отчет "Численность безработных в возрасте 15 лет и старше по субъектам Российской Федерации"', title: 'Росстат, отчет "Численность безработных в возрасте 15 лет и старше по субъектам Российской Федерации"', imported: true},
    {id: 5, name: 'Форма "Сведения об осуществлении мероприятий по повышению уровня занятости инвалидов на конец отчетного периода. Раздел V. Оказание государственной услуги по организации сопровождения при содействии занятости инвалидов"', title: 'Форма "Сведения об осуществлении мероприятий по повышению уровня занятости инвалидов на конец отчетного периода. Раздел V. Оказание государственной услуги по организации сопровождения при содействии занятости инвалидов"', imported: true}
]

/**
 * @returns {Promise<Array.<ResultMonitorChecklistValue>>}
 */
export async function loadValuesByDeparmentIdAndPeriod(departmentId, period) {
    return get(`/monitoring2023/result/checklistValues/department/bydepartment/${departmentId}/${period}`)
}


/**
 * @returns {Promise<Array.<ResultMonitorChecklistValue>>}
 */
export async function loadDepartmentValuesByRegionCodeAndPeriod(regionCode, period) {
    return get(`/monitoring2023/result/checklistValues/department/byregion/${regionCode}/${period}`)
}

/**
 * @returns {Promise<Array.<ResultMonitorChecklistValue>>}
 */
export async function loadRegionValuesByRegionCodeAndPeriod(regionCode, period) {
    return get(`/monitoring2023/result/checklistValues/region/byregion/${regionCode}/${period}`)
}

/**
 * @returns {Promise<Array.<ResultMonitorChecklistValue>>}
 */
export async function loadAllRegionValuesByPeriod(period) {
    return get(`/monitoring2023/result/checklistValues/region/${period}`)
}

/**
 * обновить список значений показателей
 * @param {Array.<ResultMonitorChecklistValue>} valueList - значения, которые нужно сохранить
 * @returns {Promise<Object>}
 */
export async function saveValues(valueList) {
    return post(`/monitoring2023/result/checklistValues/`, valueList, null)
}


/**
 * возвращает "пустое" значение чеклиста
 * @param {RegionAndDepartmentInfo} regionAndDepartmentInfo
 * @param {string} indicatorId
 * @param {number} period
 * @returns {ResultMonitorChecklistValue}
 */
export function createEmptyValue(regionAndDepartmentInfo, indicatorId, period) {
    return {
        id: null,
        configVersion: '1.0',
        regionCode: regionAndDepartmentInfo.region.code,
        department: regionAndDepartmentInfo.department,
        indicatorId: indicatorId,
        creationDateTime: null,
        period: period,
        value: null,
        controlInfo: null
    }
}

/**
 * возвращает значения только для параметров, перечисленных в valuesConfigIndicators
 * @param {Array.<ResultMonitorChecklistValue>} values
 * @param {Array.<ResultMonitorChecklistIndicator>} valuesConfigIndicators
 * @returns {Array.<ResultMonitorChecklistValue>}
 */
export function filterValues(values, valuesConfigIndicators) {
    const resValuesArray = []
    for (let value of values) {
        for (let configIndicator of valuesConfigIndicators) {
            if (value.indicatorId === configIndicator.id) {
                resValuesArray.push(value)
            }
        }
    }
    return resValuesArray
}

/**
 * найти значение в values по id
 * @param {Array.<ResultMonitorChecklistValue>} values
 * @param {string} id
 * @returns {?ResultMonitorChecklistValue}
 */
export function findValueById(values, id) {
    return values.find(e => e.indicatorId === id)
}



// async function test(){
//     console.log('testing')
//     console.log(await loadValuesByDeparmentIdAndPeriod(177575, 1))
//     console.log(await loadDepartmentValuesByRegionCodeAndPeriod('ALTK', 1))
//     console.log(await loadRegionValuesByRegionCodeAndPeriod('ALTK', 1))
//     console.log(await loadAllRegionValuesByPeriod(1))
//     console.log(await saveValues([]))
// }

