<template>
  <div>
    <div v-if="data">
      <div style="display: block; margin: 20px">
        <v-icon
            size="x-large"
            class="icon-item"
            :class="mode==='CHART' ? 'icon-poll' : 'icon-poll-grey'"
            @click="mode='CHART'"
        ></v-icon>
        <v-icon size="x-large"
                style="margin-left:15px"
                class="icon-item"
                :class="mode==='TABLE' ? 'icon-table' : 'icon-table-grey'"
                @click="mode='TABLE'"
        ></v-icon>
      </div>
      <div v-if="mode==='CHART'">
        <table class="rating_table">
          <tr v-for="(row, rowIndex) in data" :key="'chart_'+rowIndex">
            <td colspan="1">{{ row[2] }}.</td>
            <td colspan="5">{{ truncateStr(row[0], 30) }}</td>
            <td colspan="20">
              <div class="rating_bar" :style="'width: ' + row[1]*0.9+'%'" v-if="row[1]>0">_</div>{{Math.round(row[1]*100)/100}}
            </td>
          </tr>
          <tr>
            <td></td><td colspan="5"></td><td colspan="20">{{legend}}</td>
          </tr>
        </table>
      </div>
      <div v-if="mode==='CHART_old'">
        <GChart
            type="BarChart"
            :data="getChartData()"
            :options="chartOptions"
        />
      </div>
      <div v-if="mode==='TABLE'">
        <table>
          <tr>
            <th>Субъект РФ / ЦЗН</th>
            <th>Значение</th>
            <th>Место в рейтинге</th>
            <th></th>
          </tr>
          <tr v-for="(row, rowIndex) in data" :key="'_'+rowIndex">
            <td>
              {{ row[0] }}
            </td>
            <td>
              {{Math.round(row[1]*100)/100}}
            </td>
            <td>
              {{row[2]}}
            </td>
          </tr>
        </table>
      </div>
    </div>
    <v-progress-linear
        v-else
        indeterminate
        color="primary"
    ></v-progress-linear>
  </div>
</template>

<script>

import {GChart} from "vue-google-charts/legacy";
import {addRatingColumn} from "@/modules/ReportUtils";

export default {
  name: 'ResultMonitorRatingContent',
  components: {GChart},
  props: ['period', 'regionAndDepartmentInfo', 'reportDataFunction', 'legend', 'config', 'onlyCurrentRegion'],

  data: () => ({
    mode: 'CHART',
    data: undefined,
    chartOptions: {
      backgroundColor: 'transparent',
      legend: {position: "none"},
      height: 1100,
      chartArea: {width: '60%', left: 100, top: 10, height: '95%'},

      hAxis: {
        minValue: 0,
        textStyle: {
          fontSize: 10
        }
      },
      vAxis: {
        color: '#333', textStyle: {
          fontSize: 10
        }
      },
      annotations: {
        textStyle: {
          fontSize: 10,
          color: '#000',
        }
      },
      series: {
        0: {color: '#69B3E7'},
      }
    },
  }),
  methods: {
    truncateStr(str, n = 30) {
      if (!str) return str
      if (str.length > n) {
        return str.substring(0, n - 3) + "..." + str.substring(str.length - 6, str.length)
      }
      return str;
    },

    getChartData(){
      let res = this.data?.map((e, index)=>[(index+1)+'. '+e[0], e[1]])
      res?.unshift(["Субъект РФ/ЦЗН", "Значение"])
      return res
    },
    async init(){
      this.data = undefined
      this.data = await this.reportDataFunction(this.period, this.regionAndDepartmentInfo)
      if(this.config){
        this.config.configParsed = JSON.parse(this.config.configJson)
        let regionNamesToInclude = this.$cznDepartmentsByRegionList
            .filter(e=>this.config.configParsed.regions.includes(e.code))
            .map(e=>e.name)
        this.data = this.data.filter(e=>regionNamesToInclude.includes(e[0]))
      }
      if(this.onlyCurrentRegion){
        this.data = this.data.filter(e=>e[0]===this.$user.region.name)
      }
      addRatingColumn(this.data)
    }
  },
  watch: {
    regionAndDepartmentInfo: {
      async handler() {
        await this.init()
      },
      deep: true
    },
    period: {
      async handler() {
        await this.init()
      }
    }
  },
  async beforeMount() {
    await this.init()
  }
}
</script>

<style scoped>
.rating_table{
  width: 100%;
  table-layout: fixed;
  border-spacing: 0
}

.rating_bar {
  background-color: #2F80ED;
  color: #2F80ED;
  margin-right: 5px;
  display: inline-block;
}

.rating_table td {
  font-size: 10px;
  padding: 0!important;
}

.rating_table tr {
  padding: 0;
}

</style>
