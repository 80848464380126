<template>
  <div v-cloak>
    <transition name="fade" appear appear-active-class="fade-enter-active">

      <div class="wrap clientindex">
        <div class="monitor-header">
          <h1>Показатели эффективности и результативности</h1>
          <div class="download-menu">
            <v-dialog
                v-model="checkListExportDialog"
                max-width="650px"
                persistent>
              <template v-slot:activator="{ on }">
                <div class="download-menu-icon" v-on="on">
                  <svg
                      fill="none"
                      height="24"
                      viewBox="0 0 24 24"
                      width="24"
                      xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                        d="M8 17V15H16V17H8ZM16 10L12 14L8 10H10.5V7H13.5V10H16ZM5 3H19C20.11 3 21 3.9 21 5V19C21 20.11 20.11 21 19 21H5C3.9 21 3 20.11 3 19V5C3 3.9 3.9 3 5 3ZM5 5V19H19V5H5Z"
                        fill="#0033A0"
                    />
                  </svg>
                </div>
              </template>

              <OrgMonitorCheckListExportDialog
                  @close="checkListExportDialog=false; exportDialogKey++"
                  :region="regionAndDepartmentInfo.region?.name"
                  :user-department="regionAndDepartmentInfo.department"
                  :key="exportDialogKey"/>
            </v-dialog>
          </div>
        </div>
        <div class="monitor-filter">
          <RegionAndDepartmentPicker v-model="regionAndDepartmentInfo" nullable-department="true"></RegionAndDepartmentPicker>

          <div class="passport-buttons">
            <v-dialog
                v-model="dialog"
                max-width="800px"
                persistent>
              <template v-slot:activator="{ on }">
                <div v-on="on">
                  <v-btn outlined>
                    документы
                  </v-btn>
                </div>
              </template>
              <PassportInfoDocsDialog
                  :region="regionAndDepartmentInfo.region"
                  :departments="regionAndDepartmentInfo.region?.departments"
                  section="Мониторинг результативности"
                  @close="dialog=false; dialogKey++" :key="dialogKey"
                  @edit="dialog=false; dialogUpload=true;"/>
            </v-dialog>
            <v-btn outlined @click="$router.push('/orgmonitor/archive')"
                   v-if="$user.role==='ROLE_ADMIN' || $user.role==='ROLE_VNII'">
              архив<br>документов
            </v-btn>
            <v-dialog
                v-model="dialogUpload"
                max-width="800px"
                persistent>
              <PassportInfoDocsUploadDialog
                  :region="regionAndDepartmentInfo.region"
                  :departments="regionAndDepartmentInfo.region?.departments"
                  section="Мониторинг результативности"
                  :categories="passportConfig?.documentCategories"
                  @close="dialogUpload=false; dialogKey++"
                  :key="dialogKey"/>
            </v-dialog>
          </div>
        </div>

        <div class="block">
          <v-select style="width:400px"
                    :items="periods"
                    v-model="period"
                    label="Период мониторинга"
          ></v-select>
        </div>

        <v-tabs>
          <v-tab @click="checkListKey++; isMainTab = false; $forceUpdate()">Сведения для расчета показателей</v-tab>
          <v-tab @click="reportsKey++; isMainTab = true; $forceUpdate()">Отчёты</v-tab>
          <v-tab @click="ratingKey++; isMainTab = false; $forceUpdate()">Рейтинги</v-tab>
          <v-tab-item>
            <ResultMonitorCheckList
                :region-and-department-info="regionAndDepartmentInfo"
                :key="checkListKey"
                :period="period"></ResultMonitorCheckList>
          </v-tab-item>
          <v-tab-item>
            <ResultMonitorReports
                :region-and-department-info="regionAndDepartmentInfo"
                :key="reportsKey"
                :period="period"></ResultMonitorReports>
          </v-tab-item>

          <v-tab-item>
            <ResultMonitorRatings
                :region-and-department-info="regionAndDepartmentInfo"
                :key="ratingKey"
                :period="period"></ResultMonitorRatings>
          </v-tab-item>
        </v-tabs>
      </div>
    </transition>
  </div>
</template>

<script>

import ResultMonitorReports from "@/components/resultmonitor/ResultMonitorReports";
import ResultMonitorRatings from "@/components/resultmonitor/ResultMonitorRatings";
import OrgMonitorCheckListExportDialog from "@/components/dialogs/OrgMonitorCheckListExportDialog";
import PassportInfoDocsDialog from "@/components/dialogs/PassportInfoDocsDialog";
import PassportInfoDocsUploadDialog from "@/components/dialogs/PassportInfoDocsUploadDialog";
import ResultMonitorCheckList from "@/components/resultmonitor/ResultMonitorCheckList";
import RegionAndDepartmentPicker from "@/components/RegionAndDepartmentPicker";


export default {
  name: 'ResultMonitorComponent',
  components: {
    RegionAndDepartmentPicker,
    ResultMonitorReports,
    ResultMonitorRatings,
    ResultMonitorCheckList,
    PassportInfoDocsDialog,
    PassportInfoDocsUploadDialog,
    OrgMonitorCheckListExportDialog
  },

  data: () => ({
    regionAndDepartmentInfo: {region: null, department: null},
    reportsKey: 0,
    ratingKey: 0,
    checkListKey: 0,
    isMainTab: true,
    checkListExportDialog: false,
    exportDialogKey: 0,
    config: null,
    configId: null,
    dialog: false,
    dialogArchive: false,
    dialogUpload: false,
    dialogKey: 0,
    passportConfig: {},
    periods: [
      {
        text: '1 января 2024 - 30 июня 2024',
        value: 6
      },
      {
        text: '1 января 2023 - 31 декабря 2023',
        value: 5
      },
      {
        text: '1 января 2023 - 30 июня 2023',
        value: 4
      },
      {
        text: '1 января 2022 - 31 декабря 2022',
        value: 3
      },
      {
        text: '1 января 2021 - 31 декабря 2021',
        value: 1
      }
    ],
    period: 5
  }),

  methods: {
    // нужен по сути только чтобы загрузить секции документов из конфига паспорт региона
    async loadPassportConfig() {
      let req = await this.$getApi("/passport/getActiveConfig");
      if (req.ok) {
        this.passportConfig = req.payload;
        this.passportConfig.documentCategories = JSON.parse(this.passportConfig.documentCategoriesJson);
      } else {
        this.errorText =
            "Ошибка подключения к серверу или обработки запроса: " + req.error;
      }
    },
  },

  async beforeMount() {
    await this.loadPassportConfig()
  }
}
</script>

<style lang="scss">
@import "../../styles/main.scss";

.top-progress {
  margin-bottom: 30px;
  display: flex;
  align-items: center;

  > div > div {
    display: flex;
    flex-direction: column;
    height: 280px;
    border-radius: 20px;
    padding: 30px;

    div {
      font-weight: 600;
      font-size: 64px;
      line-height: 100%;
    }

    p {
      margin-top: auto;
      margin-bottom: 0;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 140%;
      color: #1C1C1F;

    }
  }

}

.v-input--radio-group.v-input--radio-group--row {
  margin: 0;
}

.date-switch {
  margin-bottom: 60px;
  display: flex;
  justify-content: center;
  color: #0033A0;
}

.clientindex {
  .v-slide-group__content.v-tabs-bar__content {
    justify-content: stretch !important;

    .v-tab {
      flex: 1 1 50%;
      max-width: initial;
    }
  }

  .v-window-item {
    padding-top: 0px;
  }

  .v-expansion-panel .level2 {
    padding-top: 5px;

    .v-expansion-panel-content {
      padding-left: 44px;
    }
  }

  .v-expansion-panel-content p {
    font-weight: 500;
    font-size: 14px;
    line-height: 160%;
    color: #1C1C1F;
    margin: 10px 0;
  }

  .list .list-item-wrap .list-item-name {
    max-width: none;

    .toggleSwitch {
      align-self: center;
    }
  }

  .list .list-item-wrap .list-item {
    justify-content: flex-start;

    .v-progress-circular {
      margin-left: 0;

      .v-progress-circular__info {
        font-size: 16px;
        font-weight: 600;
        display: block;

        .text-caption {
          font-size: 10px !important;
          vertical-align: baseline;
          display: inline;
        }
      }
    }
  }

  .list-item-wrap .list-item-wrap {
    border: none;
    padding-bottom: 5px;
  }
}

.v-tabs {
  .v-tabs-bar {
    margin: 0 0 50px 0;
  }
}

.monitor-header {
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
  gap: 20px;
  position: relative;

  h1 {
    flex: 1 1 100%;
  }

  .download-menu {
    top: 10px;
    position: relative;
    padding-left: 0;
    margin-top: 0;
    flex: 0 0 56px;
  }
}
</style>
