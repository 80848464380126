<template>
  <v-expansion-panels>
    <v-expansion-panel>
      <v-expansion-panel-header>
        <div class="report-list__item">
          <a class="report-list__title">
            <button class="toggleSwitch">
              <svg class="closed" fill="none" height="24" viewBox="0 0 24 24"
                   width="24"
                   xmlns="http://www.w3.org/2000/svg">
                <path d="M12 14.5L17 9.5L7 9.5L12 14.5Z" fill="#1551D0"/>
              </svg>
              <svg class="opened" fill="none" height="24" viewBox="0 0 24 24"
                   width="24"
                   xmlns="http://www.w3.org/2000/svg">
                <path d="M14.5 12L9.5 7L9.5 17L14.5 12Z" fill="#1551D0"/>
              </svg>
            </button>
            <span>{{title}}</span>
          </a>
          <div class="report-list__download" @click.stop="download()">
          </div>
        </div>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <ResultMonitorRatingContent
            :period="period"
            :config="config"
            :legend="legend"
            :report-data-function="reportDataFunction"
            :region-and-department-info="regionAndDepartmentInfo"
            :only-current-region="onlyCurrentRegion"
        ></ResultMonitorRatingContent>
      </v-expansion-panel-content>
    </v-expansion-panel>
    <LoaderDialog :show="showLoaderDialog"></LoaderDialog>
  </v-expansion-panels>
</template>

<script>

import LoaderDialog from "@/components/elements/LoaderDialog";
import ResultMonitorRatingContent from "@/components/resultmonitor/ResultMonitorRatingContent";
import {addRatingColumn} from "@/modules/ReportUtils";
export default {
  name: 'ResultMonitorRating',
  components: {ResultMonitorRatingContent, LoaderDialog},
  props: ['config', 'period', 'regionAndDepartmentInfo', 'title', 'reportDataFunction', 'legend', 'onlyCurrentRegion'],

  data: () => ({
    showLoaderDialog: false
  }),
  methods: {
    async download(){
      this.showLoaderDialog = true
      let data = await this.reportDataFunction(this.period, this.regionAndDepartmentInfo)
      if(this.config){
        this.config.configParsed = JSON.parse(this.config.configJson)
        let regionNamesToInclude = this.$cznDepartmentsByRegionList
            .filter(e=>this.config.configParsed.regions.includes(e.code))
            .map(e=>e.name)
        data = data.filter(e=>regionNamesToInclude.includes(e[0]))
      }
      addRatingColumn(data)
      data = this.adaptDataForExport(data)
      await this.$downloadApi("Рейтинг.xlsx","/export/xlsx", data, true)
      this.showLoaderDialog = false
    },

    adaptDataForExport(data){
      let exportData = {
        headerCells: [
          {
            caption: "Субъект РФ/ЦЗН",
            width: 5000,
          },
          {
            caption: "Значение",
            width: 5000,
          },
          {
            caption: "Место в рейтинге",
            width: 5000,
          }
        ],
        rows: []
      }

      for(let row of data){
        exportData.rows.push([
          {
            cellDataType: "STRING",
            value: row[0]
          },
          {
            cellDataType: "DECIMAL",
            value: row[1]
          },
          {
            cellDataType: typeof row[2] === 'number' ? 'INTEGER' : 'STRING',
            value: row[2]
          }
        ])

      }
      return exportData
    }
  },
  beforeMount() {
  }
}
</script>

