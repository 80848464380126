export const resultMonitorChecklistConfig = [{'id': 'U',
    'name': 'Численность безработных в отчетном периоде по данным выборочного наблюдения',
    'unit': 'чел.',
    'source': 'Росстат, отчет "Численность безработных в возрасте 15 лет и старше по субъектам Российской Федерации"',
    'checks': 'U >= 0 && U <= 10000',
    'regionSection': 'Численность безработных граждан',
    'cznSection': null},
    {'id': 'А2',
        'name': 'Численность трудоустроенных на постоянную работу за отчетный период граждан (количество закрытых в связи с трудоустройством на постоянную работу карточек персонального учета)',
        'unit': 'ед.',
        'source': 'Форма № 2-Т',
        'checks': 'А2 >= 0',
        'regionSection': 'Содействие гражданам в поиске подходящей работы',
        'cznSection': 'Содействие гражданам в поиске подходящей работы'},
    {'id': 'А3',
        'name': 'Численность трудоустроенных за отчетный период граждан (количество закрытых в связи с трудоустройством карточек персонального учета)',
        'unit': 'ед.',
        'source': 'Форма № 2-Т',
        'checks': null,
        'regionSection': 'Содействие гражданам в поиске подходящей работы',
        'cznSection': 'Содействие гражданам в поиске подходящей работы'},
    {'id': 'В12',
        'name': 'Численность граждан, признанных безработными в отчетном периоде (количество приказов)',
        'unit': 'ед.',
        'source': 'Форма № 2-Т',
        'checks': null,
        'regionSection': 'Численность безработных граждан',
        'cznSection': 'Численность безработных граждан'},
    {'id': 'А12',
        'name': 'Численность граждан, оформивших государственную регистрацию в качестве юридического лица или индивидуального предпринимателя, вставших на учет в качестве плательщика налога на профессиональный доход (далее - самозанятый), в течение отчетного периода',
        'unit': 'чел.',
        'source': 'Форма № 2-Т',
        'checks': null,
        'regionSection': 'Содействие началу осуществления предпринимательской деятельности',
        'cznSection': 'Содействие началу осуществления предпринимательской деятельности'},
    {'id': 'Ч',
        'name': 'Среднесписочная численность сотрудников ЦЗН за отчетный период',
        'unit': 'чел.',
        'source': 'РОИВ',
        'checks': null,
        'regionSection': 'Численность персонала органов занятости',
        'cznSection': null},
    {'id': 'П1',
        'name': 'Количество заявлений от граждан, обратившихся в целях поиска подходящей работы в течение отчетного периода',
        'unit': 'ед.',
        'source': 'Форма № 2-Т',
        'checks': null,
        'regionSection': 'Содействие гражданам в поиске подходящей работы',
        'cznSection': 'Содействие гражданам в поиске подходящей работы'},
    {'id': 'О31',
        'name': 'Средняя продолжительность безработицы в отчетном периоде',
        'unit': 'мес.',
        'source': 'Форма № 2-Т',
        'checks': null,
        'regionSection': 'Содействие гражданам в поиске подходящей работы',
        'cznSection': 'Содействие гражданам в поиске подходящей работы'},
    {'id': 'О32',
        'name': 'Средняя продолжительность поиска постоянной работы',
        'unit': 'мес.',
        'source': 'РОИВ',
        'checks': null,
        'regionSection': 'Содействие гражданам в поиске подходящей работы',
        'cznSection': null},
    {'id': 'П4',
        'name': 'Количество заявленных работодателями свободных рабочих мест и вакантных должностей в течение отчетного периода',
        'unit': 'ед.',
        'source': 'Форма № 2-Т',
        'checks': null,
        'regionSection': 'Содействие работодателям в подборе необходимых работников',
        'cznSection': 'Содействие работодателям в подборе необходимых работников'},
    {'id': 'Б29',
        'name': 'Количество организаций по субъектам Российской Федерации на конец отчетного периода',
        'unit': 'ед.',
        'source': 'Росстат, отчет «Количество организаций по данным государственной регистрации с 2017 г. по субъектам Российской Федерации и видам экономической деятельности»',
        'checks': null,
        'regionSection': 'Содействие работодателям в подборе необходимых работников',
        'cznSection': null},
    {'id': 'А29',
        'name': 'Количество работодателей, обратившихся за содействием в подборе необходимых работников в ЦЗН в течение отчетного периода',
        'unit': 'ед.',
        'source': 'Форма № 1-Т',
        'checks': null,
        'regionSection': 'Содействие работодателям в подборе необходимых работников',
        'cznSection': null},
    {'id': 'В5',
        'name': 'Количество свободных рабочих мест и вакантных должностей, исключенных из базы данных ЦЗН в отчетном периоде',
        'unit': 'ед.',
        'source': 'Форма № 2-Т',
        'checks': null,
        'regionSection': 'Содействие работодателям в подборе необходимых работников',
        'cznSection': 'Содействие работодателям в подборе необходимых работников'},
    {'id': 'П6',
        'name': 'Численность безработных граждан, приступивших к профессиональному обучению или дополнительному профессиональному образованию (далее также ДПО), включая обучение в другой местности, в отчетном периоде',
        'unit': 'чел.',
        'source': 'Форма № 2-Т',
        'checks': null,
        'regionSection': 'Организация профессионального обучения и ДПО безработных граждан',
        'cznSection': 'Организация профессионального обучения и ДПО безработных граждан'},
    {'id': 'С7',
        'name': 'Численность безработных граждан, завершивших профессиональное обучение или получивших ДПО отчетном периоде',
        'unit': 'чел.',
        'source': 'Форма № 2-Т',
        'checks': null,
        'regionSection': 'Организация профессионального обучения и ДПО безработных граждан',
        'cznSection': 'Организация профессионального обучения и ДПО безработных граждан'},
    {'id': 'А7',
        'name': 'Численность граждан, трудоустроенных на постоянную работу в течение отчетного периода, из числа безработных граждан, завершивших профессиональное обучение или получивших ДПО в отчетном периоде',
        'unit': 'чел.',
        'source': 'РОИВ',
        'checks': null,
        'regionSection': 'Организация профессионального обучения и ДПО безработных граждан',
        'cznSection': 'Организация профессионального обучения и ДПО безработных граждан'},
    {'id': 'Ап7',
        'name': 'Численность граждан, трудоустроенных на постоянную работу в течение отчетного периода, из числа безработных граждан, завершивших профессиональное обучение или получивших ДПО в периоде, предшествующем отчетному',
        'unit': 'чел.',
        'source': 'РОИВ',
        'checks': null,
        'regionSection': 'Организация профессионального обучения и ДПО безработных граждан',
        'cznSection': 'Организация профессионального обучения и ДПО безработных граждан'},
    {'id': 'В7',
        'name': 'Численность граждан, оформивших государственную регистрацию в качестве юридического лица или индивидуального предпринимателя, вставших на учет в качестве самозанятого в течение отчетного периода, из числа безработных граждан, завершивших профессиональное обучение или получивших ДПО в отчетном периоде',
        'unit': 'чел.',
        'source': 'РОИВ',
        'checks': null,
        'regionSection': 'Организация профессионального обучения и ДПО безработных граждан',
        'cznSection': 'Организация профессионального обучения и ДПО безработных граждан'},
    {'id': 'Вп7',
        'name': 'Численность граждан, оформивших государственную регистрацию в качестве юридического лица или индивидуального предпринимателя, вставших на учет в качестве самозанятого в течение отчетного периода, из числа безработных граждан, завершивших профессиональное обучение или получивших ДПО в периоде, предшествующем отчетному',
        'unit': 'чел.',
        'source': 'РОИВ',
        'checks': null,
        'regionSection': 'Организация профессионального обучения и ДПО безработных граждан',
        'cznSection': 'Организация профессионального обучения и ДПО безработных граждан'},
    {'id': 'П11',
        'name': 'Численность безработных граждан, получивших государственную услугу «Содействие началу осуществления предпринимательской деятельности» в отчетном периоде',
        'unit': 'чел.',
        'source': 'Форма № 2-Т',
        'checks': null,
        'regionSection': 'Содействие началу осуществления предпринимательской деятельности',
        'cznSection': 'Содействие началу осуществления предпринимательской деятельности'},
    {'id': 'П13',
        'name': 'Численность граждан, получивших государственную услугу «Организация сопровождения при содействии занятости инвалидов» (внесено в регистр получателей государственных услуг) за отчетный период',
        'unit': 'чел.',
        'source': 'Форма "Сведения об осуществлении мероприятий по повышению уровня занятости инвалидов на конец отчетного периода. Раздел V. Оказание государственной услуги по организации сопровождения при содействии занятости инвалидов"',
        'checks': null,
        'regionSection': 'Содействие занятости инвалидов',
        'cznSection': 'Содействие занятости инвалидов'},
    {'id': 'А14',
        'name': 'Численность трудоустроенных за отчетный период инвалидов из числа получивших государственную услугу «Организация сопровождения при содействии занятости инвалидов»',
        'unit': 'чел.',
        'source': 'Форма "Сведения об осуществлении мероприятий по повышению уровня занятости инвалидов на конец отчетного периода. Раздел V. Оказание государственной услуги по организации сопровождения при содействии занятости инвалидов"',
        'checks': null,
        'regionSection': 'Содействие занятости инвалидов',
        'cznSection': 'Содействие занятости инвалидов'},
    {'id': 'П16',
        'name': 'Оказано государственных услуг «Психологическая поддержка безработных граждан» в отчетном периоде',
        'unit': 'ед.',
        'source': 'Форма № 2-Т',
        'checks': null,
        'regionSection': 'Психологическая поддержка безработных граждан',
        'cznSection': 'Психологическая поддержка безработных граждан'},
    {'id': 'А17',
        'name': 'Численность граждан, трудоустроенных на постоянную работу в течение отчетного периода из числа безработных граждан, получивших государственную услугу «Психологическая поддержка безработных граждан»',
        'unit': 'чел.',
        'source': 'РОИВ',
        'checks': null,
        'regionSection': 'Психологическая поддержка безработных граждан',
        'cznSection': 'Психологическая поддержка безработных граждан'},
    {'id': 'В17',
        'name': 'Численность граждан, оформивших государственную регистрацию в качестве юридического лица или индивидуального предпринимателя, вставших на учет в качестве самозанятого в течение отчетного периода из числа безработных граждан, получивших государственную услугу «Психологическая поддержка безработных граждан»',
        'unit': 'чел.',
        'source': 'РОИВ',
        'checks': null,
        'regionSection': 'Психологическая поддержка безработных граждан',
        'cznSection': 'Психологическая поддержка безработных граждан'},
    {'id': 'П18',
        'name': 'Оказано государственных услуг «Социальная адаптация безработных граждан на рынке труда» в отчетном периоде',
        'unit': 'ед.',
        'source': 'Форма № 2-Т',
        'checks': null,
        'regionSection': 'Социальная адаптация безработных граждан на рынке труда',
        'cznSection': 'Социальная адаптация безработных граждан на рынке труда'},
    {'id': 'А19',
        'name': 'Численность граждан, трудоустроенных на постоянную работу в течение отчетного периода из числа безработных граждан, получивших государственную услугу «Социальная адаптация безработных граждан на рынке труда»',
        'unit': 'чел.',
        'source': 'РОИВ',
        'checks': null,
        'regionSection': 'Социальная адаптация безработных граждан на рынке труда',
        'cznSection': 'Социальная адаптация безработных граждан на рынке труда'},
    {'id': 'В19',
        'name': 'Численность граждан, оформивших государственную регистрацию в качестве юридического лица или индивидуального предпринимателя, вставших на учет в качестве самозанятого в течение отчетного периода из числа безработных граждан, получивших государственную услугу «Социальная адаптация безработных граждан на рынке труда»',
        'unit': 'чел.',
        'source': 'РОИВ',
        'checks': null,
        'regionSection': 'Социальная адаптация безработных граждан на рынке труда',
        'cznSection': 'Социальная адаптация безработных граждан на рынке труда'},
    {'id': 'П8',
        'name': 'Оказано государственных услуг «Организация профессиональной ориентации граждан» безработным гражданам',
        'unit': 'ед.',
        'source': 'Форма № 2-Т',
        'checks': null,
        'regionSection': 'Организация профессиональной ориентации граждан',
        'cznSection': 'Организация профессиональной ориентации граждан'},
    {'id': 'А9',
        'name': 'Оказано государственных услуг «Организация профессиональной ориентации граждан» всего',
        'unit': 'ед.',
        'source': 'Форма № 2-Т',
        'checks': null,
        'regionSection': 'Организация профессиональной ориентации граждан',
        'cznSection': 'Организация профессиональной ориентации граждан'},
    {'id': 'В9',
        'name': 'Оказано государственных услуг «Организация профессиональной ориентации граждан» гражданам в возрасте 14-17 лет',
        'unit': 'чел.',
        'source': 'Форма № 2-Т',
        'checks': null,
        'regionSection': 'Организация профессиональной ориентации граждан',
        'cznSection': 'Организация профессиональной ориентации граждан'},
    {'id': 'В10',
        'name': 'Численность безработных граждан, трудоустроенных на постоянную работу в течение отчетного периода, из числа безработных граждан, получивших государственную услугу «Организация профессиональной ориентации граждан»',
        'unit': 'чел.',
        'source': 'РОИВ',
        'checks': null,
        'regionSection': 'Организация профессиональной ориентации граждан',
        'cznSection': 'Организация профессиональной ориентации граждан'},
    {'id': 'С10',
        'name': 'Численность безработных граждан, оформивших государственную регистрацию в качестве юридического лица или индивидуального предпринимателя, вставших на учет в качестве самозанятого в течение отчетного периода, из числа безработных граждан, получивших государственную услугу «Организация профессиональной ориентации граждан»',
        'unit': 'чел.',
        'source': 'РОИВ',
        'checks': null,
        'regionSection': 'Организация профессиональной ориентации граждан',
        'cznSection': 'Организация профессиональной ориентации граждан'},
    {'id': 'А23',
        'name': 'Численность несовершеннолетних граждан в возрасте от 14 до 18 лет, обратившихся за предоставлением государственной услуги «Организация временного трудоустройства»',
        'unit': 'чел.',
        'source': 'Форма № 2-Т',
        'checks': null,
        'regionSection': 'Организация временного трудоустройства',
        'cznSection': 'Организация временного трудоустройства'},
    {'id': 'А24',
        'name': 'Численность безработных граждан, испытывающих трудности в поиске работы, обратившихся за предоставлением государственной услуги «Организация временного трудоустройства»',
        'unit': 'чел.',
        'source': 'Форма № 2-Т',
        'checks': null,
        'regionSection': 'Организация временного трудоустройства',
        'cznSection': 'Организация временного трудоустройства'},
    {'id': 'А25',
        'name': 'Численность безработных граждан в возрасте от 18 до 25 лет, имеющих высшее или среднее профессиональное образование, ищущих работу в течение года с даты выдачи им документа об образовании и о квалификации, обратившихся за предоставлением государственной услуги «Организация временного трудоустройства»',
        'unit': 'чел.',
        'source': 'Форма № 2-Т',
        'checks': null,
        'regionSection': 'Организация временного трудоустройства',
        'cznSection': 'Организация временного трудоустройства'},
    {'id': 'П20',
        'name': 'Численность несовершеннолетних граждан в возрасте от 14 до 18 лет, трудоустроенных на временные работы в свободное от учебы время',
        'unit': 'чел.',
        'source': 'Форма № 2-Т',
        'checks': null,
        'regionSection': 'Организация временного трудоустройства',
        'cznSection': 'Организация временного трудоустройства'},
    {'id': 'П21',
        'name': 'Численность безработных граждан, испытывающих трудности в поиске работы, трудоустроенных на временные работы',
        'unit': 'чел.',
        'source': 'Форма № 2-Т',
        'checks': null,
        'regionSection': 'Организация временного трудоустройства',
        'cznSection': 'Организация временного трудоустройства'},
    {'id': 'П22',
        'name': 'Численность безработных граждан в возрасте от 18 до 25 лет, имеющих высшее или среднее профессиональное образование ищущие работу в течение года с даты выдачи им документа об образовании и о квалификации, трудоустроенных на временные работы',
        'unit': 'чел.',
        'source': 'Форма № 2-Т',
        'checks': null,
        'regionSection': 'Организация временного трудоустройства',
        'cznSection': 'Организация временного трудоустройства'},
    {'id': 'А27',
        'name': 'Численность граждан, зарегистрированных в органах службы занятости в целях поиска подходящей работы (далее - граждане, зарегистрированные в целях поиска подходящей работы), в том числе безработных граждан, обратившихся за содействием в переезде в другую местность для трудоустройства',
        'unit': 'чел.',
        'source': 'Форма № 2-Т',
        'checks': null,
        'regionSection': 'Содействие в переезде и переседении в другую местность для трудоустройства',
        'cznSection': 'Содействие в переезде и переседении в другую местность для трудоустройства'},
    {'id': 'В27',
        'name': 'Численность граждан, зарегистрированных в целях поиска подходящей работы, в том числе безработных граждан, обратившихся за содействием безработным гражданам и гражданам, зарегистрированным в органах службы занятости в целях поиска подходящей работы, и членам их семей в переселении в другую местность для трудоустройства',
        'unit': 'чел.',
        'source': 'Форма № 2-Т',
        'checks': null,
        'regionSection': 'Содействие в переезде и переседении в другую местность для трудоустройства',
        'cznSection': 'Содействие в переезде и переседении в другую местность для трудоустройства'},
    {'id': 'А26',
        'name': 'Численность граждан, зарегистрированных в целях поиска подходящей работы, в том числе безработных граждан, переехавших в пределах субъекта Российской Федерации в результате получения государственной услуги «Содействие безработным гражданам и гражданам, зарегистрированным в органах службы занятости в целях поиска подходящей работы, в переезде и безработным гражданам и гражданам, зарегистрированным в органах службы занятости в целях поиска подходящей работы, и членам их семей в переселении в другую местность для трудоустройства по направлению органов службы занятости»',
        'unit': 'чел.',
        'source': 'Форма № 2-Т',
        'checks': null,
        'regionSection': 'Содействие в переезде и переседении в другую местность для трудоустройства',
        'cznSection': 'Содействие в переезде и переседении в другую местность для трудоустройства'},
    {'id': 'Б26',
        'name': 'Численность граждан, зарегистрированных в целях поиска подходящей работы, в том числе безработных граждан, переехавших в другой субъект Российской Федерации в результате получения государственной услуги «Содействие безработным гражданам и гражданам, зарегистрированным в органах службы занятости в целях поиска подходящей работы, в переезде и безработным гражданам и гражданам, зарегистрированным в органах службы занятости в целях поиска подходящей работы, и членам их семей в переселении в другую местность для трудоустройства по направлению органов службы занятости»',
        'unit': 'чел.',
        'source': 'Форма № 2-Т',
        'checks': null,
        'regionSection': 'Содействие в переезде и переседении в другую местность для трудоустройства',
        'cznSection': 'Содействие в переезде и переседении в другую местность для трудоустройства'},
    {'id': 'В26',
        'name': 'Численность, граждан, зарегистрированных в целях поиска подходящей работы, в том числе безработных граждан, переселившихся в пределах субъекта Российской Федерации в результате получения государственной услуги «Содействие безработным гражданам и гражданам, зарегистрированным в органах службы занятости в целях поиска подходящей работы, в переезде и безработным гражданам и гражданам, зарегистрированным в органах службы занятости в целях поиска подходящей работы, и членам их семей в переселении в другую местность для трудоустройства по направлению органов службы занятости»',
        'unit': 'чел.',
        'source': 'Форма № 2-Т',
        'checks': null,
        'regionSection': 'Содействие в переезде и переседении в другую местность для трудоустройства',
        'cznSection': 'Содействие в переезде и переседении в другую местность для трудоустройства'},
    {'id': 'Г26',
        'name': 'Численность, граждан, зарегистрированных в целях поиска подходящей работы, в том числе безработных граждан, переселившихся в другой субъект Российской Федерации в результате получения государственной услуги «Содействие безработным гражданам и гражданам, зарегистрированным в органах службы занятости в целях поиска подходящей работы, в переезде и безработным гражданам и гражданам, зарегистрированным в органах службы занятости в целях поиска подходящей работы, и членам их семей в переселении в другую местность для трудоустройства по направлению органов службы занятости»',
        'unit': 'чел.',
        'source': 'Форма № 2-Т',
        'checks': null,
        'regionSection': 'Содействие в переезде и переседении в другую местность для трудоустройства',
        'cznSection': 'Содействие в переезде и переседении в другую местность для трудоустройства'},
    {'id': 'А15',
        'name': 'Численность инвалидов, трудоустроенных за отчетный период (количество закрытых в связи с трудоустройством карточек персонального учета)',
        'unit': 'ед.',
        'source': 'Форма № 2-Т',
        'checks': null,
        'regionSection': 'Содействие занятости инвалидов',
        'cznSection': 'Содействие занятости инвалидов'},
    {'id': 'Z15',
        'name': 'Количество заявлений от инвалидов, обратившихся в органы службы занятости за содействием в поиске подходящей работы за отчетный период',
        'unit': 'ед.',
        'source': 'Форма № 2-Т',
        'checks': null,
        'regionSection': 'Содействие занятости инвалидов',
        'cznSection': 'Содействие занятости инвалидов'}]








export const resultMonitorIndicatorsConfig = [{'id': '1',
    'name': 'Количество заявлений от граждан, обратившихся в целях поиска подходящей работы в течение отчетного периода',
    'unit': 'чел.',
    'formula': 'П1',
    'type': 'П',
    'section': 'Показатели результативности оказания государственной услуги «Содействие гражданам в поиске подходящей работы»'},
    {'id': '2',
        'name': 'Доля трудоустроенных на постоянную работу в численности граждан, обратившихся в целях поиска подходящей работы',
        'unit': '%',
        'formula': 'П1 !=0 ? А2/П1 : 0',
        'type': 'П',
        'section': 'Показатели результативности оказания государственной услуги «Содействие гражданам в поиске подходящей работы»'},
    {'id': '2а',
        'name': 'Доля трудоустроенных в численности граждан, обратившихся в целях поиска подходящей работы\t',
        'unit': '%',
        'formula': 'П1 !=0 ? А3/П1 : 0',
        'type': 'П',
        'section': 'Показатели результативности оказания государственной услуги «Содействие гражданам в поиске подходящей работы»'},
    {'id': '3',
        'name': 'Динамика средней продолжительности безработицы по отношению к аналогичному периоду предшествующего календарного года',
        'unit': '%',
        'formula': 'Опред31 != 0 ? О31/Опред31-1 : 0',
        'type': 'О',
        'section': 'Показатели результативности оказания государственной услуги «Содействие гражданам в поиске подходящей работы»'},
    {'id': '4',
        'name': 'Количество заявленных работодателями свободных рабочих мест и вакантных должностей',
        'unit': 'ед.',
        'formula': 'П4',
        'type': 'П',
        'section': 'Показатели результативности оказания государственной услуги «Содействие работодателям в подборе необходимых работников»'},
    {'id': '5',
        'name': 'Отношение численности трудоустроенных граждан к количеству свободных рабочих мест и вакантных должностей, исключенных из базы данных ЦЗН за отчетный период',
        'unit': '%',
        'formula': 'В5 != 0 ? А3/В5 : 0',
        'type': 'П',
        'section': 'Показатели результативности оказания государственной услуги «Содействие работодателям в подборе необходимых работников»'},
    {'id': '6',
        'name': 'Численность безработных граждан, приступивших к профессиональному обучению или дополнительного профессиональному образованию, включая обучение в другой местности',
        'unit': 'чел.',
        'formula': 'П6',
        'type': 'П',
        'section': 'Показатели результативности оказания государственной услуги «Организация профессионального обучения и дополнительного профессионального образования безработных граждан, включая обучение в другой местности»'},
    {'id': '7',
        'name': 'Доля граждан, нашедших доходное занятие (в том числе трудоустроенных) после завершения профессионального обучения или получения дополнительного профессионального образования из числа безработных граждан, завершивших профессиональное обучение или получивших ДПО в отчетном периоде или периоде, предшествующем отчетному',
        'unit': '%',
        'formula': 'С7 == 0  ?  0 :\nС7 < (Ап7 + А7 + Вп7 + В7) ? 1 :\n(Ап7 + А7 + Вп7 + В7) / С7',
        'type': 'П',
        'section': 'Показатели результативности оказания государственной услуги «Организация профессионального обучения и дополнительного профессионального образования безработных граждан, включая обучение в другой местности»'},
    {'id': '8',
        'name': 'Оказано государственных услуг «Организация профессиональной ориентации граждан» безработным гражданам',
        'unit': 'ед.',
        'formula': 'П8',
        'type': 'П',
        'section': 'Показатели результативности оказания государственной услуги «Организация профессиональной ориентации граждан»'},
    {'id': '9',
        'name': 'Оказано государственных услуг «Организация профессиональной ориентации граждан» гражданам, не относящимся к категории безработных',
        'unit': 'ед.',
        'formula': 'А9 - П8',
        'type': 'П',
        'section': 'Показатели результативности оказания государственной услуги «Организация профессиональной ориентации граждан»'},
    {'id': '10',
        'name': 'Доля безработных граждан, нашедших доходное занятие после получения государственной услуги «Организация профессиональной ориентации граждан», в численности безработных граждан, получивших указанную услугу',
        'unit': '%',
        'formula': ' П8 == 0  ? 0 :\n П8 < В10 + С10  ?  1 :\n(В10 + С10) / П8',
        'type': 'П',
        'section': 'Показатели результативности оказания государственной услуги «Организация профессиональной ориентации граждан»'},
    {'id': '10а',
        'name': 'Отношение количества оказанных услуг «Организация профессиональной ориентации граждан» гражданам старше 17 лет к количеству заявлений от граждан, обратившихся в целях поиска подходящей работы в течение отчетного периода',
        'unit': '%',
        'formula': ' П1 == 0   ? 0 :\n П1 < А9   ? 1 :\n (А9 - В9) / П1',
        'type': 'П',
        'section': 'Показатели результативности оказания государственной услуги «Организация профессиональной ориентации граждан»'},
    {'id': '11',
        'name': 'Численность безработных граждан, получивших государственную услугу «Содействие началу осуществления предпринимательской деятельности»',
        'unit': 'чел.',
        'formula': 'П11',
        'type': 'П',
        'section': 'Показатели результативности оказания государственной услуги «Содействие началу осуществления предпринимательской деятельности»'},
    {'id': '12',
        'name': 'Отношение численности граждан, оформивших государственную регистрацию в качестве юридического лица или индивидуального предпринимателя, вставших на учет в качестве самозанятого в течение отчетного периода, к численности граждан, признанных безработными в отчетном периоде',
        'unit': '%',
        'formula': 'В12 != 0 ? А12 / В12 : 1',
        'type': 'П',
        'section': 'Показатели результативности оказания государственной услуги «Содействие началу осуществления предпринимательской деятельности»'},
    {'id': '13',
        'name': 'Численность граждан, получивших государственную услугу «Организация сопровождения при содействии занятости инвалидов» (внесено в регистр получателей государственных услуг) за отчетный период',
        'unit': 'чел.',
        'formula': 'П13',
        'type': 'П',
        'section': 'Показатели результативности оказания государственной услуги «Организация сопровождения при содействии занятости инвалидов» с учетом результативности осуществления полномочий по содействию занятости инвалидов'},
    {'id': '14',
        'name': 'Доля трудоустроенных инвалидов в общей численности инвалидов, получивших государственную услугу «Организация сопровождения при содействии занятости инвалидов»',
        'unit': '%',
        'formula': 'П13 == 0   ? 0 :\nП13 < А14   ? 1 :\nА14 / П13',
        'type': 'П',
        'section': 'Показатели результативности оказания государственной услуги «Организация сопровождения при содействии занятости инвалидов» с учетом результативности осуществления полномочий по содействию занятости инвалидов'},
    {'id': '15',
        'name': 'Доля трудоустроенных инвалидов из числа инвалидов, обратившихся в органы службы занятости за содействием в поиске подходящей работы',
        'unit': '%',
        'formula': 'Z15 != 0 ? А15 / Z15 : 0',
        'type': 'П',
        'section': 'Показатели результативности оказания государственной услуги «Организация сопровождения при содействии занятости инвалидов» с учетом результативности осуществления полномочий по содействию занятости инвалидов'},
    {'id': '16',
        'name': 'Оказано государственных услуг «Психологическая поддержка безработных граждан»',
        'unit': 'ед.',
        'formula': 'П16',
        'type': 'П',
        'section': 'Показатели результативности оказания государственной услуги «Психологическая поддержка безработных граждан»'},
    {'id': '17',
        'name': 'Доля граждан, нашедших доходное занятие после получения государственной услуги «Психологическая поддержка безработных граждан» в численности безработных граждан, получивших указанную услугу',
        'unit': '%',
        'formula': 'П16 != 0 ? (А17 + В17) / П16 : 0',
        'type': 'П',
        'section': 'Показатели результативности оказания государственной услуги «Психологическая поддержка безработных граждан»'},
    {'id': '18',
        'name': 'Оказано государственных услуг «Социальная адаптация безработных граждан на рынке труда»',
        'unit': 'ед.',
        'formula': 'П18',
        'type': 'П',
        'section': 'Показатели результативности оказания государственной услуги «Социальная адаптация безработных граждан на рынке труда»'},
    {'id': '19',
        'name': 'Доля граждан, нашедших доходное занятие после получения государственной услуги «Социальная адаптация безработных граждан на рынке труда» в численности безработных граждан, получивших указанную услугу',
        'unit': '%',
        'formula': 'П18 != 0 ? (А19 + В19) / П18 : 0',
        'type': 'П',
        'section': 'Показатели результативности оказания государственной услуги «Социальная адаптация безработных граждан на рынке труда»'},
    {'id': '20',
        'name': 'Численность несовершеннолетних граждан в возрасте от 14 до 18 лет, трудоустроенных на временные работы в свободное от учебы время',
        'unit': 'чел.',
        'formula': 'П20',
        'type': 'П',
        'section': 'Показатели результативности оказания государственной услуги «Организация временного трудоустройства»'},
    {'id': '21',
        'name': 'Численность безработных граждан, испытывающих трудности в поиске работы, трудоустроенных на временные работы',
        'unit': 'чел.',
        'formula': 'П21',
        'type': 'П',
        'section': 'Показатели результативности оказания государственной услуги «Организация временного трудоустройства»'},
    {'id': '22',
        'name': 'Численность безработных граждан в возрасте от 18 до 25 лет, имеющих высшее или среднее профессиональное образование, ищущих работу в течение года с даты выдачи им документа об образовании и о квалификации, трудоустроенных на временные работы',
        'unit': 'чел.',
        'formula': 'П22',
        'type': 'П',
        'section': 'Показатели результативности оказания государственной услуги «Организация временного трудоустройства»'},
    {'id': '23',
        'name': 'Доля несовершеннолетних граждан в возрасте от 14 до 18 лет, трудоустроенных на временные работы в свободное от учебы время, из числа граждан данной категории, обратившихся за предоставлением государственной услуги «Организация временного трудоустройства» или согласились с предложением о ее предоставлении',
        'unit': '%',
        'formula': 'А23 == 0  ? 0 :\nП20 > А23  ? 1 :\nП20 / А23',
        'type': 'П',
        'section': 'Показатели результативности оказания государственной услуги «Организация временного трудоустройства»'},
    {'id': '24',
        'name': 'Доля безработных граждан, испытывающих трудности в поиске работы, трудоустроенных на временные работы, из числа безработных граждан, обратившихся за предоставлением государственной услуги «Организация временного трудоустройства» или согласились с предложением о ее предоставлении',
        'unit': '%',
        'formula': 'А24 == 0  ? 0 :\nП21 > А24  ? 1 :\nП21 / А24',
        'type': 'П',
        'section': 'Показатели результативности оказания государственной услуги «Организация временного трудоустройства»'},
    {'id': '25',
        'name': 'Доля безработных граждан в возрасте от 18 до 25 лет, имеющих высшее или среднее профессиональное образование, ищущих работу в течение года с даты выдачи им документа об образовании и о квалификации, трудоустроенных на временные работы, из числа граждан данной категории, обратившихся за предоставлением государственной услуги «Организация временного трудоустройства» или согласились с предложением о ее предоставлении',
        'unit': '%',
        'formula': 'А25 == 0  ? 0 :\nП22 > А25  ? 1 :\nП22 / А25',
        'type': 'П',
        'section': 'Показатели результативности оказания государственной услуги «Организация временного трудоустройства»'},
    {'id': '26',
        'name': 'Численность граждан, зарегистрированных в целях поиска подходящей работы, в том числе безработных граждан, осуществивших переезд или переселение в результате получения государственной услуги «Содействие безработным гражданам и гражданам, зарегистрированным в органах службы занятости в целях поиска подходящей работы, в переезде и безработным гражданам и гражданам, зарегистрированным в органах службы занятости» в целях поиска подходящей работы, и членам их семей в переселении в другую местность для трудоустройства по направлению органов службы занятости»',
        'unit': 'чел.',
        'formula': 'А26 + Б26 + В26 + Г26',
        'type': 'П',
        'section': 'Показатели результативности оказания государственной услуги «Содействие безработным гражданам и гражданам, зарегистрированным в органах службы занятости в целях поиска подходящей работы, в переезде и безработным гражданам и гражданам, зарегистрированным в органах службы занятости в целях поиска подходящей работы, и членам их семей в переселении в другую местность для трудоустройства по направлению органов службы занятости»'},
    {'id': '27',
        'name': 'Доля граждан, зарегистрированных в целях поиска подходящей работы, в том числе безработных граждан, осуществивших переезд или переселение в результате получения государственной услуги «Содействие безработным гражданам и гражданам, зарегистрированным в органах службы занятости в целях поиска подходящей работы, в переезде и безработным гражданам и гражданам, зарегистрированным в органах службы занятости в целях поиска подходящей работы, и членам их семей в переселении в другую местность для трудоустройства по направлению органов службы занятости», в численности граждан, обратившихся за указанной услугой или согласившихся с предложением о ее предоставлении',
        'unit': '%',
        'formula': 'А27 + В27 == 0  ? 0 :\nП26 > А27 + В27  ? 1 :\nП26 / (А27 + В27)',
        'type': 'П',
        'section': 'Показатели результативности оказания государственной услуги «Содействие безработным гражданам и гражданам, зарегистрированным в органах службы занятости в целях поиска подходящей работы, в переезде и безработным гражданам и гражданам, зарегистрированным в органах службы занятости в целях поиска подходящей работы, и членам их семей в переселении в другую местность для трудоустройства по направлению органов службы занятости»'},
    {'id': '28',
        'name': 'Отношение численности граждан, обратившихся в ЦЗН в целях поиска подходящей работы, к численности безработных по данным выборочного наблюдения',
        'unit': '%',
        'formula': 'U != 0 ? П1 / U : 0',
        'type': 'П',
        'section': 'Показатели эффективности деятельности органов службы занятости'},
    {'id': '29',
        'name': 'Доля работодателей-организаций, обратившихся за содействием в подборе необходимых работников в ЦЗН, в общем количестве организаций',
        'unit': '%',
        'formula': 'Б29 != 0 ? А29 / Б29 : 0',
        'type': 'П',
        'section': 'Показатели эффективности деятельности органов службы занятости'},
    {'id': '30',
        'name': 'Доля граждан, нашедших доходное занятие (т.е. граждан, трудоустроенных на постоянную работу, и граждан, оформивших государственную регистрацию в качестве юридического лица или индивидуального предпринимателя, вставших на учет в качестве самозанятого), в численности граждан, обратившихся в ЦЗН в целях поиска подходящей работы',
        'unit': '%',
        'formula': 'П1 != 0 ? (А2 + А12) / П1 : 0',
        'type': 'П',
        'section': 'Показатели эффективности деятельности органов службы занятости'},
    {'id': '31д',
        'name': 'Средняя продолжительность безработицы в днях',
        'unit': 'дней',
        'formula': '30 * О31',
        'type': 'О',
        'section': 'Показатели эффективности деятельности органов службы занятости'},
    {'id': '32д',
        'name': 'Средняя продолжительность поиска постоянной работы в днях',
        'unit': 'дней',
        'formula': '30 * О32',
        'type': 'О',
        'section': 'Показатели эффективности деятельности органов службы занятости'},
    {'id': '33',
        'name': 'Численность граждан, нашедших при содействии ЦЗН доходное занятие (граждан, трудоустроенных на постоянную работу, и граждан, оформивших государственную регистрацию в качестве юридического лица или индивидуального предпринимателя, вставших на учет в качестве самозанятого), в расчете на одного сотрудника ЦЗН',
        'unit': 'чел.',
        'formula': 'Ч != 0 ? (А2 + А12) / Ч : 0',
        'type': 'П',
        'section': 'Показатели эффективности деятельности органов службы занятости'}]

