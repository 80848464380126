
import {
    loadAllRegionValuesByPeriod,
    loadChecklistConfig, loadDepartmentValuesByRegionCodeAndPeriod,
    loadIndicatorsConfig,
    resultMonitorChecklistSources
} from "@/modules/ResultMonitor";

let departmentsByRegion
export function setDepartmentsByRegion(val){ //нужно чтобы передать в модуль перечень субъектов рф с цзн
    departmentsByRegion = JSON.parse(JSON.stringify(val))
}

/**
 * Отчет по субъектам Российской Федерации о заполнении сведений, необходимых для оценки результативности оказания государственных услуг по оценки эффективности и результативности деятельности
 * @return {Promise<Object>}
 */
export async function getSubjectReportData(period) {
    const config = await loadChecklistConfig()
    const values = await loadAllRegionValuesByPeriod(period)

    let reportData = {
        headerCells: [
            {
                caption: "Субъект РФ",
                width: 5000,
            }
        ],
        rows: []
    }

    for(let source of resultMonitorChecklistSources){
        reportData.headerCells.push({width: 5000, caption: 'Заполнение сведений раздела "' +source.title + '", %'})
    }
    for(let region of departmentsByRegion){
        let row = [
            {cellDataType: "STRING", value: region.name}
        ]
        for(let source of resultMonitorChecklistSources){
            row.push({
                cellDataType: "PERCENT",
                value: getSubjectReportDataFillPercentage(config, values, region.code, source.name),
            })
        }
        reportData.rows.push(row)
    }

    return reportData
}


function getSubjectReportDataFillPercentage(config, values, regionCode, sourceName){
    let configParams = config.filter(e=>e.source===sourceName).map(e=>e.id)
    let valuesFiltered = values.filter(e=>e.value != null &&
        e.value !== '' &&
        e.regionCode===regionCode &&
        configParams.includes(e.indicatorId))
    return valuesFiltered.length / configParams.length
}












/**
 * Считает и заполняет для регионов значения показателей
 * @return {Promise<Object>}
 */
async function computeIndicators(period){
    const indicatorsConfig = await loadIndicatorsConfig()
    const currentValues = await loadAllRegionValuesByPeriod(period)
    const previousValues = await loadAllRegionValuesByPeriod(period-2)
    for(let region of departmentsByRegion){
        region.resultMonitorValues = {}
        for(let indicatorConfig of indicatorsConfig){
            if(!indicatorConfig.formulaAdapted) indicatorConfig.formulaAdapted = adaptFormula(indicatorConfig.formula)
            computeIndicatorForRegion(region, indicatorConfig, currentValues, previousValues)
        }
    }
}

/**
 * Считает и заполняет для ЦЗН значения показателей
 * @return {Promise<Object>}
 */
async function computeDepartmentIndicators(regionWithDepartments, currentValues, previousValues){
    const indicatorsConfig = await loadIndicatorsConfig()
    for(let department of regionWithDepartments.departments){
        department.resultMonitorValues = {}
        for(let indicatorConfig of indicatorsConfig){
            if(!indicatorConfig.formulaAdapted) indicatorConfig.formulaAdapted = adaptFormula(indicatorConfig.formula)
            computeIndicatorForDepartment(regionWithDepartments.code, department, indicatorConfig, currentValues, previousValues)
        }
    }
}

function adaptFormula(formula){
    return formula
        .replaceAll(/([a-zA-Zа-яА-Я]+\d*)/g, (a, indicatorFullCode)=>'gv(currentValues, previousValues, region, department, \'' + indicatorFullCode + '\')')
}

// eslint-disable-next-line no-unused-vars
function gv(currentValues, previousValues, region, department, indicatorFullCode){
    //возможно, данные расчетные - смотрим показатель в region или department
    if(department && department.resultMonitorValues[indicatorFullCode]){
        return department.resultMonitorValues[indicatorFullCode]
    } else if(!department && region.resultMonitorValues[indicatorFullCode]){
        return region.resultMonitorValues[indicatorFullCode]
    }
    // не расчетный показатель. ищем в индикаторах с сервера
    if(indicatorFullCode.includes('пред')){
        return getValue(previousValues, region.code, department, indicatorFullCode.replace('пред', ''))
    } else {
        return getValue(currentValues, region.code, department, indicatorFullCode)
    }
}

// eslint-disable-next-line no-unused-vars
function getValue(values, region, department, indicatorId){
    let v
    if(department){
        v = values.find(e=>e.department && e.department.id===department.id && e.indicatorId===indicatorId)
    } else {
        v = values.find(e=>e.regionCode===region && e.department == null && e.indicatorId===indicatorId)
    }
    if(!v || !v.value) return 0
    return v.value
}

// eslint-disable-next-line no-unused-vars
function computeIndicatorForRegion(region, indicatorConfig, currentValues, previousValues){
    // eslint-disable-next-line no-unused-vars
    let department = null
    region.resultMonitorValues[indicatorConfig.type+indicatorConfig.id] = eval(indicatorConfig.formulaAdapted)
}

// eslint-disable-next-line no-unused-vars
function computeIndicatorForDepartment(region, department, indicatorConfig, currentValues, previousValues){
    department.resultMonitorValues[indicatorConfig.type+indicatorConfig.id] = eval(indicatorConfig.formulaAdapted)
}



/**
 * Отчет по субъектам Российской Федерации по секции
 * @return {Promise<Object>}
 */
export async function getByServiceReportData(sectionName, period){
    await computeIndicators(period)
    let indicatorsConfig = (await loadIndicatorsConfig())
    if(sectionName){
        indicatorsConfig = indicatorsConfig.filter(e=>e.section===sectionName)
    }
    let reportData = {
        headerCells: [
            {
                caption: "Субъект РФ",
                width: 5000,
            }
        ],
        rows: []
    }

    for(let indicatorConfig of indicatorsConfig){
        reportData.headerCells.push({
            caption: indicatorConfig.type + indicatorConfig.id + ". " + indicatorConfig.name,
            width: 5000,
        })
    }

    for(let region of departmentsByRegion) {
        let row = [
            {cellDataType: "STRING", value: region.name}
        ]
        for (let indicatorConfig of indicatorsConfig) {
            row.push({
                cellDataType: getCellDataTypeFromUnit(indicatorConfig.unit),
                value: region.resultMonitorValues[indicatorConfig.type+indicatorConfig.id],
            })
        }
        reportData.rows.push(row)
    }

    return reportData
}

/**
 * Сводный отчет по ЦЗН субъекта Российской Федерации по показателям результативности оказания государственных услуг
 * @return {Promise<Object>}
 */
export async function getRegionalReport(period, regionAndDepartmentInfo){
    let regionWithDepartments = departmentsByRegion.find(e=>e.code === regionAndDepartmentInfo.region.code)
    const currentValues = await loadDepartmentValuesByRegionCodeAndPeriod(regionWithDepartments.code, period)
    const previousValues = await loadDepartmentValuesByRegionCodeAndPeriod(regionWithDepartments.code, period-2)

    await computeDepartmentIndicators(regionWithDepartments, currentValues, previousValues)
    const checklistConfig = await loadChecklistConfig()
    const indicatorsConfig = await loadIndicatorsConfig()

    let reportData = {
        headerCells: [
            {
                caption: "ЦЗН",
                width: 10000,
            }
        ],
        rows: []
    }

    for(let question of checklistConfig){
        reportData.headerCells.push({
            caption: question.id + ". " + question.name,
            width: 5000,
        })
    }
    for(let indicator of indicatorsConfig){
        reportData.headerCells.push({
            caption: "П" + indicator.id + ". " + indicator.name,
            width: 5000,
        })
    }

    for(let department of regionWithDepartments.departments){
        let row = [
            {cellDataType: "STRING", value: department.fullName}
        ]
        for(let question of checklistConfig){
            row.push({
                cellDataType: "DECIMAL",
                value: getValue(currentValues, null, department, question.id),
            })
        }
        for(let indicator of indicatorsConfig){
            row.push({
                cellDataType: getCellDataTypeFromUnit(indicator.unit),
                value: department.resultMonitorValues[indicator.type+indicator.id],
            })
        }
        reportData.rows.push(row)
    }

    return reportData
}


/**
 * Отчет по отдельному ЦЗН по показателям результативности оказания государственных услуг
 * @return {Promise<Object>}
 */
export async function getCznReport(period, regionAndDepartmentInfo){
    let regionWithDepartments = departmentsByRegion.find(e=>e.code === regionAndDepartmentInfo.region.code)
    let department = regionWithDepartments.departments.find(e=>e.id === regionAndDepartmentInfo.department.id)
    const currentValues = await loadDepartmentValuesByRegionCodeAndPeriod(regionWithDepartments.code, period)
    const previousValues = await loadDepartmentValuesByRegionCodeAndPeriod(regionWithDepartments.code, period-2)
    await computeDepartmentIndicators(regionWithDepartments, currentValues, previousValues)
    const checklistConfig = await loadChecklistConfig()
    const indicatorsConfig = await loadIndicatorsConfig()
    let reportData = {
        headerCells: [
            {
                caption: "ЦЗН",
                width: 10000,
            }
        ],
        rows: []
    }
    //
    for(let question of checklistConfig){
        reportData.headerCells.push({
            caption: question.id + ". " + question.name,
            width: 5000,
        })
    }
    for(let indicator of indicatorsConfig){
        reportData.headerCells.push({
            caption: "П" + indicator.id + ". " + indicator.name,
            width: 5000,
        })
    }

    let row = [
        {cellDataType: "STRING", value: department.fullName}
    ]
    for(let question of checklistConfig){
        row.push({
            cellDataType: "DECIMAL",
            value: getValue(currentValues, null, department, question.id),
        })
    }
    for(let indicator of indicatorsConfig){
        row.push({
            cellDataType: getCellDataTypeFromUnit(indicator.unit),
            value: department.resultMonitorValues[indicator.type+indicator.id],
        })
    }
    reportData.rows.push(row)
    indicatorsConfig
    checklistConfig
    department
    return reportData
}


//value is in element[1] place
function findMinValue(arr){
    let curMin = 1e12
    for(let el of arr){
        if(el[1] < curMin){
            curMin = el[1]
        }
    }
    return curMin
}

//value is in element[1] place
function findMaxValue(arr){
    let curMax = -1e12
    for(let el of arr){
        if(el[1] > curMax){
            curMax = el[1]
        }
    }
    return curMax
}

function normalizeRating(rating, type){
    let min = findMinValue(rating)
    let max = findMaxValue(rating)
    if(max === min) {
        max = 1
        min = 0
    }
    if(type==='П') {
        rating = rating.map(e => [e[0], (e[1] - min) / (max-min) * 100])
    } else if(type==='О'){
        rating = rating.map(e => [e[0], (max - e[1]) / (max-min) * 100])
    } else {
        console.error('Unknown rating normalization type ', type)
        return undefined
    }

    rating.sort((a,b)=>a[1]<=b[1]?1:-1)
    return rating
}

export async function getRatingByIndicator(level, indicatorId, period, regionAndDepartmentInfo){
    let baseObjects = await getRegionsWithComputedIndicators(level, period, regionAndDepartmentInfo)
    const config = await loadIndicatorsConfig()
    const indicator = config.find(e=>e.id==indicatorId)
    return normalizeRating(baseObjects.map(e => [e.name, e.resultMonitorValues[indicator.type+indicatorId]]), indicator.type)
}

export async function getRatingWorksearchService(level, period, regionAndDepartmentInfo){
    let baseObjects = await getRegionsWithComputedIndicators(level, period, regionAndDepartmentInfo)
    return normalizeRating(baseObjects.map(
        e => [e.name, e.resultMonitorValues['П2']*0.33 + e.resultMonitorValues['П2а']*0.33 + e.resultMonitorValues['О3']*0.34]
    ), 'П')
}

export async function getRatingProfOrientation(level, period, regionAndDepartmentInfo){
    let baseObjects = await getRegionsWithComputedIndicators(level, period, regionAndDepartmentInfo)
    return normalizeRating(baseObjects.map(
        e => [e.name, e.resultMonitorValues['П10']*0.5 + e.resultMonitorValues['П10а']*0.5]
    ), 'П')
}

export async function getRatingInvalidsService(level, period, regionAndDepartmentInfo){
    let baseObjects = await getRegionsWithComputedIndicators(level, period, regionAndDepartmentInfo)
    return normalizeRating(baseObjects.map(
        e => [e.name, e.resultMonitorValues['П14']*0.33 + e.resultMonitorValues['П15']*0.67]
    ), 'П')
}

export async function getRatingTemporaryService(level, period, regionAndDepartmentInfo){
    let baseObjects = await getRegionsWithComputedIndicators(level, period, regionAndDepartmentInfo)
    return normalizeRating(baseObjects.map(
        e => [e.name, e.resultMonitorValues['П23'] * 0.3 + e.resultMonitorValues['П24'] * 0.35 + e.resultMonitorValues['П25'] * 0.35]
    ), 'П')
}

async function getCznsWithComputedIndicators(period, regionAndDepartmentInfo){
    let regionWithDepartments = departmentsByRegion.find(e=>e.code === regionAndDepartmentInfo.region.code)
    const currentValues = await loadDepartmentValuesByRegionCodeAndPeriod(regionWithDepartments.code, period)
    const previousValues = await loadDepartmentValuesByRegionCodeAndPeriod(regionWithDepartments.code, period-2)
    await computeDepartmentIndicators(regionWithDepartments, currentValues, previousValues)
    return regionWithDepartments.departments.filter(e=>e.type==='territorial_czn')
}

async function getRegionsWithComputedIndicators(level, period, regionAndDepartmentInfo){
    if(level === 'REGIONAL'){
        await computeIndicators(period)
        return departmentsByRegion
    } else if(level === 'CZN'){
        return getCznsWithComputedIndicators(period, regionAndDepartmentInfo)
    }
}

export async function getServiceIntegralRating(level, period, regionAndDepartmentInfo){
    let baseObjects = await getRegionsWithComputedIndicators(level, period, regionAndDepartmentInfo)
    return normalizeRating(baseObjects.map(
        e => [
            e.name,
            0.20*(  e.resultMonitorValues['П2']*0.33 + e.resultMonitorValues['П2а']*0.33 + e.resultMonitorValues['О3']*0.34  )+
            0.28*(  e.resultMonitorValues['П5']  )+
            0.14*(  e.resultMonitorValues['П7']  )+
            0.04*(  e.resultMonitorValues['П10']*0.5 + e.resultMonitorValues['П10а']*0.5  )+
            0.09*(  e.resultMonitorValues['П12']  )+
            0.09*(  e.resultMonitorValues['П14']*0.33 + e.resultMonitorValues['П15']*0.67  )+
            0.04*(  e.resultMonitorValues['П17']  )+
            0.04*(  e.resultMonitorValues['П19']  )+
            0.04*(  e.resultMonitorValues['П23']*0.3 + e.resultMonitorValues['П24']*0.35 + e.resultMonitorValues['П25']*0.35  )+
            0.04*(  e.resultMonitorValues['П27']  )
        ]
    ), 'П')
}

export async function getEffectivenessIntegralRating(period){
    let baseObjects = await getRegionsWithComputedIndicators('REGIONAL', period, null)
    return normalizeRating(baseObjects.map(
        e => [
            e.name,
            0.1*e.resultMonitorValues['П28'] + 0.1*e.resultMonitorValues['П29'] + 0.4*e.resultMonitorValues['П30'] + 0.15*e.resultMonitorValues['О31д'] + 0.15*e.resultMonitorValues['О32д'] + 0.15*e.resultMonitorValues['П33']
        ]
    ), 'П')
}

function getCellDataTypeFromUnit(unit){
    switch (unit){
        case 'чел.': case 'ед.': case 'мес.': case 'тыс. чел.': case 'дней':
            return 'DECIMAL'
        case '%':
            return 'PERCENT'
    }
}




export const resultMonitorRatings = [
    {
        id: 50,
        title: 'Интегральный рейтинг результативности оказания государственных услуг',
        dataFunction: getServiceIntegralRating.bind(null, 'REGIONAL')
    },
    {
        id: 31,
        title: 'Сводный рейтинг результативности оказания государственной услуги «Содействие гражданам в поиске подходящей работы»',
        dataFunction: getRatingWorksearchService.bind(null, 'REGIONAL')
    },
    {
        id: 32,
        title: 'Сводный рейтинг результативности оказания государственной услуги «Содействие работодателям в подборе необходимых работников»',
        dataFunction: getRatingByIndicator.bind(null, 'REGIONAL', 5)
    },
    {
        id: 33,
        title: 'Сводный рейтинг результативности оказания государственной услуги «Организация профессионального обучения и дополнительного профессионального образования безработных граждан, включая обучение в другой местности»',
        dataFunction: getRatingByIndicator.bind(null, 'REGIONAL', 7)
    },
    {
        id: 34,
        title: 'Сводный рейтинг результативности оказания государственной услуги «Организация профессиональной ориентации граждан»',
        dataFunction: getRatingProfOrientation.bind(null, 'REGIONAL')
    },
    {
        id: 35,
        title: 'Сводный рейтинг результативности оказания государственной услуги «Содействие началу осуществления предпринимательской деятельности»',
        dataFunction: getRatingByIndicator.bind(null, 'REGIONAL', 12)
    },
    {
        id: 36,
        title: 'Сводный рейтинг результативности оказания государственной услуги «Организация сопровождения при содействии занятости инвалидов», а также осуществления полномочий по содействию занятости инвалидов',
        dataFunction: getRatingInvalidsService.bind(null, 'REGIONAL')
    },
    {
        id: 37,
        title: 'Сводный рейтинг результативности оказания государственной услуги «Психологическая поддержка безработных граждан»',
        dataFunction: getRatingByIndicator.bind(null, 'REGIONAL', 17)
    },
    {
        id: 38,
        title: 'Сводный рейтинг результативности оказания государственной услуги «Социальная адаптация безработных граждан на рынке труда»',
        dataFunction: getRatingByIndicator.bind(null, 'REGIONAL', 19)
    },
    {
        id: 39,
        title: 'Сводный рейтинг результативности оказания государственной услуги «Организация временного трудоустройства»',
        dataFunction: getRatingTemporaryService.bind(null, 'REGIONAL')
    },
    {
        id: 40,
        title: 'Сводный рейтинг результативности оказания государственной услуги «Содействие безработным гражданам и гражданам, зарегистрированным в органах службы занятости в целях поиска подходящей работы, в переезде и безработным гражданам и гражданам, зарегистрированным в органах службы занятости в целях поиска подходящей работы, и членам их семей в переселении в другую местность для трудоустройства по направлению органов службы занятости»',
        dataFunction: getRatingByIndicator.bind(null, 'REGIONAL',  27)
    },
    {
        id: 4,
        title: 'Рейтинг субъектов РФ по показателю П7. Доля граждан, нашедших доходное занятие (в том числе трудоустроенных) после завершения профессионального обучения или получения дополнительного профессионального образования из числа безработных граждан, завершивших профессиональное обучение или получивших ДПО в отчетном периоде или периоде, предшествующем отчетному',
        dataFunction: getRatingByIndicator.bind(null, 'REGIONAL', 7)
    },
    {
        id: 5,
        title: 'Рейтинг субъектов РФ по показателю П10. Доля безработных граждан, получивших государственную услугу "организация профессиональной ориентации граждан" перед направлением на профессиональное обучение, дополнительное профессиональное образование или нашедших доходное занятие после получения услуги "организация профессиональной ориентации граждан", в численности безработных граждан, получивших указанную услугу',
        dataFunction: getRatingByIndicator.bind(null, 'REGIONAL', 10)
    },
    {
        id: 6,
        title: 'Рейтинг субъектов РФ по показателю П12. Доля безработных граждан, начавших предпринимательскую деятельность после получения государственной услуги "содействие началу осуществления предпринимательской деятельности безработных граждан", в численности безработных граждан, получивших указанную услугу',
        dataFunction: getRatingByIndicator.bind(null, 'REGIONAL', 12)
    },
    {
        id: 7,
        title: 'Рейтинг субъектов РФ по показателю П14. Доля трудоустроенных инвалидов в общей численности инвалидов, получивших услугу "организация сопровождения при содействии занятости инвалидов"',
        dataFunction: getRatingByIndicator.bind(null, 'REGIONAL', 14)
    },
    {
        id: 8,
        title: 'Рейтинг субъектов РФ по показателю П15. Доля трудоустроенных инвалидов из числа инвалидов, обратившихся в органы службы занятости за содействием в поиске подходящей работы',
        dataFunction: getRatingByIndicator.bind(null, 'REGIONAL', 15)
    },
    {
        id: 9,
        title: 'Рейтинг субъектов РФ по показателю П17. Доля граждан, нашедших доходное занятие после получения государственной услуги "психологическая поддержка безработных граждан" в численности безработных граждан, получивших указанную услугу',
        dataFunction: getRatingByIndicator.bind(null, 'REGIONAL', 17)
    },
    {
        id: 10,
        title: 'Рейтинг субъектов РФ по показателю П19. Доля граждан, нашедших доходное занятие после получения услуги "социальная адаптация безработных граждан на рынке труда" в численности безработных граждан, получивших указанную услугу',
        dataFunction: getRatingByIndicator.bind(null, 'REGIONAL', 19)
    },
    {
        id: 11,
        title: 'Рейтинг субъектов РФ по показателю П23. Доля несовершеннолетних граждан в возрасте от 14 до 18 лет, трудоустроенных на временные работы в свободное от учебы время, из числа граждан данной категории, обратившихся за предоставлением государственной услуги «Организация временного трудоустройства» или согласились с предложением о ее предоставлении',
        dataFunction: getRatingByIndicator.bind(null, 'REGIONAL', 23)
    },
    {
        id: 12,
        title: 'Рейтинг субъектов РФ по показателю П24. Доля безработных граждан, испытывающих трудности в поиске работы, трудоустроенных на временные работы, из числа безработных граждан, обратившихся за предоставлением государственной услуги "Организация временного трудоустройства" или согласились с предложением о ее предоставлении',
        dataFunction: getRatingByIndicator.bind(null, 'REGIONAL', 24)
    },
    {
        id: 13,
        title: 'Доля безработных граждан в возрасте от 18 до 25 лет, имеющих высшее или среднее профессиональное образование, ищущих работу в течение года с даты выдачи им документа об образовании и о квалификации, трудоустроенных на временные работы, из числа граждан данной категории, обратившихся за предоставлением государственной услуги "Организация временного трудоустройства" или согласились с предложением о ее предоставлении',
        dataFunction: getRatingByIndicator.bind(null, 'REGIONAL', 25)
    },
    {
        id: 27,
        title: 'Доля безработных граждан, получивших государственную услугу "Содействие безработным гражданам   и гражданам, зарегистрированным в органах службы занятости в целях поиска подходящей работы, в переезде и безработным гражданам и гражданам, зарегистрированным в органах службы занятости в целях поиска подходящей работы, и членам их семей в переселении в другую местность для трудоустройства по направлению органов службы занятости ", в численности граждан, обратившихся за указанной услугой или согласившихся с предложением о ее предоставлении',
        dataFunction: getRatingByIndicator.bind(null, 'REGIONAL', 25)
    }
]


export const effectivenessRatings = [
    {
        id: 60,
        title: 'Совокупный (интегральный) рейтинг СЗН по совокупности показателей эффективности и результативности',
        dataFunction: getEffectivenessIntegralRating
    },
    {
        id: 61,
        title: 'Отношение численности граждан, обратившихся в ЦЗН в целях поиска подходящей работы, к численности безработных по данным выборочного наблюдения',
        dataFunction: getRatingByIndicator.bind(null, 'REGIONAL', 28)
    },
    {
        id: 62,
        title: 'Доля работодателей, обратившихся за содействием в ЦЗН, в общем количестве работодателей',
        dataFunction: getRatingByIndicator.bind(null, 'REGIONAL', 29)
    },
    {
        id: 63,
        title: 'Доля граждан, нашедших доходное занятие (т.е. граждан, трудоустроенных на постоянную работу, и граждан, оформивших государственную регистрацию в качестве юридического лица или индивидуального предпринимателя, вставших на учет в качестве самозанятого), в численности граждан, обратившихся в ЦЗН в целях поиска подходящей работы',
        dataFunction: getRatingByIndicator.bind(null, 'REGIONAL', 30)
    },
    {
        id: 64,
        title: 'Средняя продолжительность безработицы',
        dataFunction: getRatingByIndicator.bind(null, 'REGIONAL', '31д')
    },
    {
        id: 65,
        title: 'Средняя продолжительность поиска постоянной работы',
        dataFunction: getRatingByIndicator.bind(null, 'REGIONAL', '32д')
    },
    {
        id: 66,
        title: 'Численность граждан, нашедших при содействии ЦЗН доходное занятие (граждан, трудоустроенных на постоянную работу, и граждан, оформивших государственную регистрацию в качестве юридического лица или индивидуального предпринимателя, вставших на учет в качестве самозанятого), в расчете на одного сотрудника ЦЗН',
        dataFunction: getRatingByIndicator.bind(null, 'REGIONAL', 33)
    },
]
